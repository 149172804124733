// A react component to support electron's auth operation to clerk.

import { SignedIn, SignedOut, SignInButton } from "@clerk/clerk-react";
import { Button, Container } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { REACT_APP_STUDIO_SIGN_OUT_REDIRECT_URL } from "../../configs/config";
import { UserContext } from "../../context/UserContext";

const ElectronAuthSignOut: React.FC = () => {
    const { isLoaded, isSignedIn, signOut } = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        setLoading(true);

        if (isLoaded && isSignedIn) {
            signOut(REACT_APP_STUDIO_SIGN_OUT_REDIRECT_URL)
                .catch((error) => {
                    console.error("Error occurred while signing out.", error);
                    setError("Error occurred while signing out.");
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            console.log("UserContext is not loaded.", isLoaded, isSignedIn);
        }

        setLoading(false);
    }, [isLoaded, isSignedIn, signOut]);

    if (loading) return <p>Loading...</p>;
    if (error)
        return (
            <SignedIn>
                <p>{error}</p>;
            </SignedIn>
        );
    return (
        <Container
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "60vh", // Modified line
            }}
        >
            <SignedOut>
                <h1 style={{ textAlign: "center", fontSize: "2rem", color: "white" }}>
                    You have successfully signed out. Thank you for using Muggle AI.
                </h1>
                <SignInButton>
                    <Button variant="contained" color="warning">
                        Sign In Again
                    </Button>
                </SignInButton>
            </SignedOut>
        </Container>
    );
};

export default ElectronAuthSignOut;
