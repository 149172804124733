import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Slider,
  Typography,
} from "@mui/material";
import React from "react";
import { PromptFilter } from "../../types/discovery/PromptFilter";
import { PromptFilterOptionDataType } from "../../types/discovery/PromptFilterOptionType";
import { PromptFilterDisplayTypeEnum } from "../../types/discovery/PromptFilterSectionTypeEnum";
import { PromptFilterType } from "../../types/discovery/PromptFilterType";
import { SideFilterStateType } from "../../types/discovery/SideFilterStateType";

interface SideFilterProps {
  processedData: PromptFilterOptionDataType[];
  setProcessedData: React.Dispatch<
    React.SetStateAction<PromptFilterOptionDataType[]>
  >;
  filterStates: SideFilterStateType;
  setFilterStates: React.Dispatch<React.SetStateAction<SideFilterStateType>>;
}

const SideFilter: React.FC<SideFilterProps> = ({
  processedData,
  setProcessedData,
  filterStates,
  setFilterStates,
}) => {
  const filterOptionChangeHandler =
    (optionFilter: PromptFilterType) =>
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (checked) {
        setFilterStates((prev: SideFilterStateType) => ({
          ...prev,
          [optionFilter.field_name]: [
            ...new Set([
              ...(prev[optionFilter.field_name] as string[]),
              event.target.name,
            ]),
          ],
        }));
      } else {
        setFilterStates((prev: SideFilterStateType) => ({
          ...prev,
          [optionFilter.field_name]: (
            prev[optionFilter.field_name] as string[]
          ).filter((value) => value !== event.target.name),
        }));
      }
    };
  const CreateFilterRangeChangeHandler =
    (rangeFilter: PromptFilterType) =>
    (event: Event, newValue: number[] | number, activeThumb: number) => {
      return setFilterStates((prev: SideFilterStateType) => ({
        ...prev,
        [rangeFilter.field_name]: newValue as number[],
      }));
    };

  return (
    <Box>
      <Typography variant="h4" sx={{ color: "white" }}>
        Filters
      </Typography>
      <hr />
      <Box>
        {Object.values(PromptFilter).map(
          (filter: PromptFilterType, index: number) => (
            <Accordion key={filter.field_name}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                key={filter.field_name}>
                <Typography>{filter.label}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {filter.display_type === PromptFilterDisplayTypeEnum.Option ? (
                  <FormControl
                    component="fieldset"
                    variant="filled"
                    margin="dense"
                    size="small">
                    <FormGroup>
                      {[
                        ...new Set(
                          processedData
                            .map(
                              (pd: PromptFilterOptionDataType) =>
                                pd[filter.field_name]
                            )
                            .map(
                              (
                                value:
                                  | string
                                  | string[]
                                  | number
                                  | number[]
                                  | any
                              ) => {
                                if (Array.isArray(value)) {
                                  return value;
                                } else if (
                                  typeof value === "number" ||
                                  typeof value === "string"
                                ) {
                                  return [value];
                                } else {
                                  return []; // Skip properties (object)
                                }
                              }
                            )
                            .flat()
                        ),
                      ].map((value) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="small"
                              checked={
                                (
                                  filterStates[filter.field_name] as string[]
                                ).indexOf(value) > -1
                              }
                              onChange={filterOptionChangeHandler(filter)}
                              name={value}
                            />
                          }
                          label={value}
                          key={value}
                        />
                      ))}
                    </FormGroup>
                  </FormControl>
                ) : filter.display_type ===
                  PromptFilterDisplayTypeEnum.Range ? (
                  <Slider
                    getAriaLabel={() => "Minimum distance"}
                    value={filterStates[filter.field_name] as number[]}
                    onChange={CreateFilterRangeChangeHandler(filter)}
                    valueLabelDisplay="auto"
                    key={filter.field_name}
                    disableSwap
                  />
                ) : (
                  ""
                )}
              </AccordionDetails>
            </Accordion>
          )
        )}
      </Box>
    </Box>
  );
};

export default SideFilter;

