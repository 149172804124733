import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { type Socket } from "socket.io-client";
import { IAgent } from "../../../../models/interfaces/workbench/agent/IAgent";
import { IAgentInstance } from "../../../../models/interfaces/workbench/agent/IAgentInstance";
import { killChat } from "../../ManageConversation";
import { agentStatusBoxStyle, agentStatusStyle } from "../ChatboxStyle";

interface ActiveAgentListProps {
    socket: Socket | undefined;
    chatSessionId: string;
    activeAgents: IAgentInstance[];
    setIsSelectAgentModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    selectedAgents: { [key: string]: IAgent };
    startNewConversationAsync: (agentList: {
        [agentId: string]: number;
    }) => Promise<void>;
}

const ActiveAgentList: React.FC<ActiveAgentListProps> = ({
    socket,
    activeAgents,
    chatSessionId,
    setIsSelectAgentModalOpen,
    selectedAgents,
    startNewConversationAsync,
}) => {
    return (
        <Grid container sx={agentStatusBoxStyle} direction="column">
            <Grid item xs={1}>
                <Typography>
                    <b>Active Agents</b>
                </Typography>
            </Grid>
            {activeAgents === undefined ||
            activeAgents == null ||
            activeAgents.length === 0 ? (
                <Grid item xs={1} sx={agentStatusStyle}>
                    No active agents
                </Grid>
            ) : (
                activeAgents?.map((agent, index) => (
                    <Grid item xs={1} key={index} sx={agentStatusStyle}>
                        <Typography align="left" fontSize="medium">
                            <CheckCircleIcon
                                fontSize="small"
                                color="success"
                                sx={{
                                    verticalAlign: "middle",
                                    fontSize: "small",
                                    mr: 1,
                                }}
                            />
                            {agent["agentName"]}
                        </Typography>
                    </Grid>
                ))
            )}
            <Grid item xs={1} sx={agentStatusStyle}>
                <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={() => {
                        setIsSelectAgentModalOpen(true);
                    }}
                >
                    Invite Agent
                </Button>
            </Grid>
            <Grid item xs={1} sx={agentStatusStyle}>
                <Button
                    variant="contained"
                    color="error"
                    fullWidth
                    onClick={() => {
                        killChat({
                            socket: socket,
                            chatSessionId: chatSessionId,
                        });
                    }}
                >
                    Kill Chat
                </Button>
            </Grid>
            <Grid item xs={2} sx={agentStatusStyle}>
                <Button
                    variant="contained"
                    color="warning"
                    fullWidth
                    onClick={async () => {
                        await startNewConversationAsync(
                            Object.values(selectedAgents)
                                .map((x) => {
                                    return {
                                        agentId: x.agentId,
                                        versionId: x.versionId,
                                    };
                                })
                                .reduce((acc, x) => {
                                    acc[x.agentId] = x.versionId;
                                    return acc;
                                }, {} as { [agentId: string]: number })
                        );
                    }}
                >
                    Restart Chat
                </Button>
            </Grid>
        </Grid>
    );
};

export default ActiveAgentList;
