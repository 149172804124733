// A react component to display a list of action, and allow the user to add new key value pair or edit existing ones.

import { useAuth } from "@clerk/clerk-react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
    Autocomplete,
    Box,
    Checkbox,
    TextField,
    darken,
    lighten,
    styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { REACT_APP_BACKEND_PROTECTED_URL } from "../../../../configs/config";
import { IAction } from "../../../../models/interfaces/workbench/agent/IAction";
import GetGlobalHeader from "../../../../utils/auth_utils";

interface ActionEditBoxProps {
    userId: string;
    isSavePromptModalOpen: boolean;
    setIsSavePromptModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    selectedAction: { [key: string]: IAction };
    setSelectedAction: React.Dispatch<
        React.SetStateAction<{ [key: string]: IAction }>
    >;
    agentActions: { [key: string]: IAction };
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const GroupHeader = styled("div")(({ theme }) => ({
    position: "sticky",
    top: "-8px",
    padding: "4px 10px",
    color: theme.palette.primary.main,
    backgroundColor:
        theme.palette.mode === "light"
            ? lighten(theme.palette.primary.light, 0.85)
            : darken(theme.palette.primary.main, 0.8),
}));
const GroupItems = styled("ul")({
    padding: 0,
});

const ActionEditBox: React.FC<ActionEditBoxProps> = (props) => {
    const [availableActions, setAvailableActions] = useState<{
        [key: string]: IAction;
    }>({});

    const { getToken } = useAuth();

    useEffect(() => {
        if (props.isSavePromptModalOpen) {
            const getAvailableActionAsync = async () => {
                try {
                    const response = await fetch(
                        `${REACT_APP_BACKEND_PROTECTED_URL}/agent/action/filter?ownerId=${props.userId}`,
                        GetGlobalHeader({
                            method: "GET",
                            authToken: (await getToken()) ?? "",
                        })
                    );
                    const data = await response.json();
                    if (!response.ok)
                        throw new Error(
                            "API request failed: " + response.statusText
                        );
                    if (data) {
                        const actionArr = data as { [key: string]: IAction };
                        setAvailableActions(actionArr);
                    }
                } catch (error) {
                    console.error(
                        "An error occurred while saving the agent:",
                        error
                    );
                }
            };
            getAvailableActionAsync();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isSavePromptModalOpen, props.userId]);

    return (
        <Box display="flex" flexDirection="column">
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <Autocomplete
                    multiple
                    id="action-assign-checkboxes"
                    limitTags={2}
                    size="small"
                    sx={{
                        width: "500px",
                    }}
                    options={Object.keys(availableActions)}
                    disableCloseOnSelect
                    blurOnSelect={false}
                    defaultValue={Object.values(props.agentActions).map(
                        (action) => action.actionName
                    )}
                    onChange={(event, newValue) => {
                        const val = newValue.reduce(
                            (acc: { [key: string]: IAction }, cur) => {
                                acc[cur] = availableActions[cur];
                                return acc;
                            },
                            {}
                        );
                        props.setSelectedAction(val);
                    }}
                    getOptionLabel={(option) => option}
                    groupBy={(option) => option.split("_")[0]}
                    renderGroup={(params) => (
                        <li key={params.key}>
                            <GroupHeader>{params.group}</GroupHeader>
                            <GroupItems>{params.children}</GroupItems>
                        </li>
                    )}
                    renderOption={(props, option, { selected }) => (
                        <li
                            {...props}
                            style={{
                                padding: "2px",
                                fontSize: "medium",
                                minHeight: "30px",
                            }}
                        >
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option}
                        </li>
                    )}
                    renderInput={(params) => <TextField {...params} />}
                />
            </Box>
        </Box>
    );
};
export default ActionEditBox;
