import { type SxProps, type Theme } from "@mui/system";

export const titleStyle: SxProps<Theme> = {
  textAlign: "center",
  m: 2,
};
export const discoveryCardBox: SxProps<Theme> = {
  marginTop: "10px",
  maxHeight: "70px",
  overflowY: "auto",
  display: "flex",
  flexWrap: "wrap",
  gap: "4px",
  maxWidth: "100%",
};
export const containerStyle: SxProps<Theme> = {
  maxWidth: "1920px",
};

export const textFieldStyle: SxProps<Theme> = {
  variant: "outlined",
  color: "primary",
  bgcolor: "#fff",
  mt: "50px",
  width: "100%",
};

export const buttonBoxStyle: SxProps<Theme> = {
  display: "flex",
  justifyContent: "center",
  mb: 3,
};

export const buttonStyle: SxProps<Theme> = {
  variant: "contained",
};

export const sortedDropdownByStyle: SxProps<Theme> = {
  display: "flex",
  justifyContent: "flex-end",
  alignContent: "center",
};

export const cardBoxStyle: SxProps<Theme> = {
  display: "flex",
  flexDirection: { xs: "column", sm: "row" },
  justifyContent: "space-between",
  m: 3,
  gap: 2,
};

export const modalBoxStyle: SxProps<Theme> = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 6,
};
export const modalBuildButton: SxProps<Theme> = {
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "center",
  width: "100%",
  p: 2,
  marginTop: "40%",
  background: "#1976d2", // Material-UI primary color
  //   color: 'white'
};

export const modalCloseButton: SxProps<Theme> = {
  p: 2,
  mt: 0,
  mb: 3,
  marginTop: "0",
  background: "primary", // Material-UI primary color
  //   color: 'white'
};
