import { Box, Modal, Tab } from "@mui/material";
import React, { useEffect } from "react";

import SavePromptView from "./SavePromptView";

import { TabContext, TabList, TabPanel } from "@mui/lab";
import { ISavePromptModalProp } from "../../../../models/interfaces/workbench/page_prop/ISavePromptModalProp";
import { LoadEntityTypeEnum } from "../../../../types/entity/LoadEntityEnum";
import SaveAgentView from "./SaveAgentView";
import { modalBoxStyle } from "./SavePromptModalStyle";
import SaveSolutionView from "./SaveSolutionView";

const SavePromptModal: React.FC<ISavePromptModalProp> = ({ props }) => {
    const [selectedTabKey, setSelectedTabKey] = React.useState(
        props.entityType
    );
    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setSelectedTabKey(newValue as LoadEntityTypeEnum);
    };

    useEffect(() => {
        setSelectedTabKey(props.entityType);
    }, [props.entityType]);

    return (
        <Modal
            open={props.isSavePromptModalOpen}
            onClose={() => props.setIsSavePromptModalOpen(false)}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box sx={modalBoxStyle}>
                <TabContext value={selectedTabKey}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <TabList
                            onChange={handleTabChange}
                            aria-label={`Load an entity tab`}
                        >
                            {Object.keys(LoadEntityTypeEnum).map((key) => {
                                return (
                                    <Tab key={key} label={key} value={key} />
                                );
                            })}
                        </TabList>
                    </Box>
                    <TabPanel value={LoadEntityTypeEnum.Prompt.toString()}>
                        <SavePromptView props={props} />
                    </TabPanel>
                    <TabPanel value={LoadEntityTypeEnum.Agent.toString()}>
                        <SaveAgentView props={props} />
                    </TabPanel>
                    <TabPanel value={LoadEntityTypeEnum.Solution.toString()}>
                        <SaveSolutionView props={props} />
                    </TabPanel>
                </TabContext>
            </Box>
        </Modal>
    );
};

export default SavePromptModal;
