// The MUI version of ./Chatbox.module.css
import { SxProps, Theme } from "@mui/material";

const agentStatusBoxStyle: SxProps<Theme> = {
    backgroundColor: "#fff",
    minHeight: "20vh",
    maxHeight: "70vh",
    width: "200px",
    overflowY: "auto",
    verticalAlign: "center",
    lineHeight: 1.5,
    fontFamily: "sans-serif",
    fontSize: "small",
    alignContent: "left",
    padding: "10px",
};

const agentStatusStyle: SxProps<Theme> = {
    marginBottom: "5px",
    padding: "5px",
};

export { agentStatusBoxStyle, agentStatusStyle };

