import { convert } from "html-to-text";

export class StringUtils {
    static IsStringUndefinedNullOrEmpty(
        str: string | undefined | null
    ): boolean {
        return str === undefined || str === null || str === "";
    }

    static CapStringSize(str: string, size: number): string {
        return str.length > size ? str.substring(0, size) + "..." : str;
    }

    static GetHtmlFreePromptContent = (
        textContainsHtmlLineBreak: string
    ): string => {
        if (StringUtils.IsStringUndefinedNullOrEmpty(textContainsHtmlLineBreak))
            return "";

        const convStr = convert(textContainsHtmlLineBreak, {
            wordwrap: 1000,
            preserveNewlines: true,
        }); // Use a long wordwrap to avoid breaking the text
        return convStr;
    };
}
