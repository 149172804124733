import { Box, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { sortedDropdownByStyle } from "../../pages/main-layout/DiscoveryStyles";

const SORTED_BY = [
    { value: "popularity", label: "Most Popular" },
    { value: "relavance", label: "Most Relevant" },
    { value: "timestamp", label: "Most Recent" },
];

interface SortBarProps {
    sort: string;
    setSort: React.Dispatch<React.SetStateAction<string>>;
}

export const SortBar: React.FC<SortBarProps> = ({ sort, setSort }: SortBarProps) => {
    const handleSortChange = (event: SelectChangeEvent): void => {
        setSort(event.target.value);
    };

    return (
        <Grid container justifyContent="center" alignContent="center">
            <Grid item xs={16} sm={8}>
                <Box sx={sortedDropdownByStyle}>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="demo-simple-select-helper-label" variant="standard">
                            Sort By:
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-helper-label"
                            displayEmpty
                            value={sort}
                            onChange={handleSortChange}
                            label="Sort By:"
                        >
                            {SORTED_BY.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </Grid>
        </Grid>
    );
};
