import {
    Box,
    Button,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import React, { useState } from "react";

import { useAuth } from "@clerk/clerk-react";
import { REACT_APP_BACKEND_PROTECTED_URL } from "../../../../configs/config";
import { IPrompt } from "../../../../models/interfaces/prompt/IPrompt";
import { AgentFunctionRoleEnum } from "../../../../models/interfaces/workbench/agent/AgentFunctionRoleEnum";
import { IAction } from "../../../../models/interfaces/workbench/agent/IAction";
import { IAgent } from "../../../../models/interfaces/workbench/agent/IAgent";
import { IAgentPromptView } from "../../../../models/interfaces/workbench/agent/IAgentPromptView";
import { ISavePromptModalProp } from "../../../../models/interfaces/workbench/page_prop/ISavePromptModalProp";
import GetGlobalHeader from "../../../../utils/auth_utils";
import { StringUtils } from "../../../../utils/string_utils";
import ActionEditBox from "./ActionEditBox";
import PropertiesEditBox from "./PropertiesEditBox";
import TagsEditBox from "./TagsEditBox";

const agentPromptUrl = `${REACT_APP_BACKEND_PROTECTED_URL}/agentPrompt`;
const createOptions = (body: string, authToken?: string) =>
    GetGlobalHeader({
        method: "POST",
        body: body,
        authToken: authToken,
    });
const updateOptions = (body: string, authToken?: string) =>
    GetGlobalHeader({
        method: "PUT",
        body: body,
        authToken: authToken,
    });

const SaveAgentView: React.FC<ISavePromptModalProp> = ({
    props,
}: ISavePromptModalProp) => {
    const [selectedAction, setSelectedAction] = useState<{
        [key: string]: IAction;
    }>(props.agent?.agentActions ?? {});

    const { getToken } = useAuth();

    const handleSaveAgent = async (): Promise<void> => {
        try {
            const newPromptObj: IPrompt = {
                ...props.prompt,
                promptId: props.prompt.promptId ?? null,
                ownerId: props.userId,
            };

            const newAgentObj: IAgentPromptView = {
                agentId: props.agent?.agentId ?? "",
                agentName: props.agent?.agentName ?? "",
                agentFunctionRole:
                    props.agent?.agentFunctionRole ??
                    AgentFunctionRoleEnum.Assistant,
                agentActions: selectedAction,
                llmEngineName: "OpenAI",
                prompt: newPromptObj,
                ownerId: props.userId,
            };
            console.log(`Saving agent ${JSON.stringify(newAgentObj)}`);
            const tarUrl = `${agentPromptUrl}${
                StringUtils.IsStringUndefinedNullOrEmpty(props.agent?.agentId) // Update or create agent?
                    ? ""
                    : `/${props.agent?.agentId}`
            }`;
            const authToken = (await getToken()) ?? "";
            const opt = StringUtils.IsStringUndefinedNullOrEmpty(
                props.agent?.agentId
            )
                ? createOptions(JSON.stringify(newAgentObj), authToken)
                : updateOptions(JSON.stringify(newAgentObj), authToken);
            const response = await fetch(tarUrl, opt);
            const data = await response.json();
            if (!response.ok)
                throw new Error("API request failed: " + response.statusText);
            console.log(`Agent saved successfully ${JSON.stringify(data)} `);

            if (data) {
                const agentDef: IAgent = {
                    ...data,
                    promptId: data.prompt.promptId,
                };
                props.setAgent(agentDef);
                setSelectedAction(agentDef.agentActions);

                props.setSelectedAgents((prev) => {
                    const next = { ...prev };
                    next[agentDef.agentId] = agentDef;
                    return next;
                });

                props.setPrompt(
                    (data as unknown as IAgentPromptView).prompt as IPrompt
                );
            }
            props.setIsSavePromptModalOpen(false);
        } catch (error) {
            console.error("An error occurs", error);
        }
    };

    return (
        <Typography id="modal-description">
            {/* Modal Content */}
            <Box display="flex" height={500}>
                {/* Left Half Area */}
                <Box
                    flex="1"
                    mr={2}
                    overflow="auto"
                    pr={2}
                    borderRight="1px solid gray"
                >
                    <Box flex="1" overflow="auto">
                        <Typography variant="subtitle1">Agent Name</Typography>
                        <TextField
                            fullWidth
                            label="Give your agent a name *"
                            variant="filled"
                            placeholder="Your agent's name"
                            onChange={(e) => {
                                props.setAgent((prev) => {
                                    if (prev === undefined) {
                                        return {
                                            agentName: e.target.value,
                                        } as IAgent;
                                    }

                                    return {
                                        ...prev,
                                        agentName: e.target.value,
                                    };
                                });
                                props.setPrompt((prev) => {
                                    return {
                                        ...prev,
                                        promptName: e.target.value,
                                    };
                                });
                            }}
                            value={props.agent?.agentName}
                        />
                    </Box>
                    <Box flex="1" overflow="auto">
                        <Typography variant="subtitle1">
                            Agent Function Role
                        </Typography>
                        <Select
                            fullWidth
                            value={props.agent?.agentFunctionRole}
                            onChange={(e) => {
                                props.setAgent((prev) => {
                                    const newVal =
                                        AgentFunctionRoleEnum[
                                            e.target
                                                .value as AgentFunctionRoleEnum
                                        ];
                                    if (prev === undefined) {
                                        return {
                                            agentFunctionRole: newVal,
                                        } as IAgent;
                                    }

                                    return {
                                        ...prev,
                                        agentFunctionRole: newVal,
                                    };
                                });
                            }}
                        >
                            {Object.values(AgentFunctionRoleEnum).map(
                                (role) => (
                                    <MenuItem
                                        key={role}
                                        value={role}
                                        selected={
                                            role ===
                                            props.agent?.agentFunctionRole
                                        }
                                    >
                                        {role}
                                    </MenuItem>
                                )
                            )}
                        </Select>
                    </Box>
                    <Box flex="1" overflow="auto" mt={2}>
                        <Typography variant="subtitle1">
                            Agent Prompt
                        </Typography>
                        <Stack direction="row">
                            <Box
                                sx={{
                                    whiteSpace: "pre-wrap",
                                    overflowX: "clip",
                                }}
                            >
                                {StringUtils.GetHtmlFreePromptContent(
                                    props.prompt.promptContent
                                ) || ""}
                            </Box>
                        </Stack>
                    </Box>
                </Box>
                {/* Right Half Area */}
                <Box
                    flex="1"
                    ml={2}
                    overflow="auto"
                    display="flex"
                    flexDirection="column"
                >
                    <Box flex="1">
                        <Typography variant="subtitle1">Description</Typography>
                        <TextField
                            id="prompt-description"
                            label="Description"
                            variant="outlined"
                            placeholder="Your agent's description"
                            onChange={(e) =>
                                props.setPrompt((prev) => {
                                    return {
                                        ...prev,
                                        promptDescription: e.target.value,
                                    };
                                })
                            }
                            value={props.prompt.promptDescription}
                            multiline
                            fullWidth
                        />
                    </Box>
                    {/* Action Section */}
                    <Box flex="1" mt={2}>
                        <Typography variant="subtitle1">Actions</Typography>
                        <ActionEditBox
                            userId={props.userId}
                            isSavePromptModalOpen={props.isSavePromptModalOpen}
                            setIsSavePromptModalOpen={
                                props.setIsSavePromptModalOpen
                            }
                            selectedAction={selectedAction}
                            setSelectedAction={setSelectedAction}
                            agentActions={props.agent?.agentActions ?? {}}
                        />
                    </Box>
                    {/* Properties Section */}
                    <Box flex="1" mt={2}>
                        <Typography variant="subtitle1">Properties</Typography>
                        <PropertiesEditBox
                            properties={props.prompt.properties}
                            propertiesChange={(newProperties) => {
                                props.setPrompt((prev) => {
                                    return {
                                        ...prev,
                                        properties: newProperties,
                                    };
                                });
                            }}
                        />
                    </Box>
                    {/* Tags Section */}
                    <Box flex="1" mt={2}>
                        <Typography variant="subtitle1">Tags</Typography>
                        <TagsEditBox
                            tags={props.prompt.tags}
                            tagsChange={(newTags) => {
                                props.setPrompt((prev) => {
                                    return { ...prev, tags: newTags };
                                });
                            }}
                        />
                    </Box>
                </Box>
            </Box>
            {/* Buttom Buttons Area */}
            <Box mt={2} display="flex" justifyContent="flex-end">
                <Box padding={2}>
                    <Button
                        variant="contained"
                        color="warning"
                        onClick={() => props.setIsSavePromptModalOpen(false)}
                    >
                        Cancel
                    </Button>
                </Box>
                <Box padding={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSaveAgent}
                    >
                        Save Agent Prompt
                    </Button>
                </Box>
            </Box>
        </Typography>
    );
};
export default SaveAgentView;
