// A hook to manage solution state

import { useEffect, useState } from "react";
import { GetSolutionById } from "../components/solution/ManageSolution";
import { ISolution } from "../models/interfaces/solution/ISolution";
import { StringUtils } from "../utils/string_utils";

function useSolution(solutionId: string) {
    const [solution, setSolution] = useState<ISolution>();

    useEffect(() => {
        if (StringUtils.IsStringUndefinedNullOrEmpty(solutionId)) return;

        GetSolutionById(solutionId)
            .then((sol) => {
                setSolution(sol);
            })
            .catch((err) => {
                console.log(err);
            });

        return () => {
            setSolution({} as ISolution);
        };
    });

    return { solution, setSolution };
}

export default useSolution;
