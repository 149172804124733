import { Box, Card, CardContent, Chip, Typography } from "@mui/material";
import React, { useState } from "react";
import { discoveryCardBox } from "../pages/main-layout/DiscoveryStyles";
import { CardProps } from "../types/CardProps";
import PromptDetailsModal from "./common/PopupWindow";

const DiscoveryCard: React.FC<CardProps> = ({ prompt, examples, instructions, metrics, constraints }) => {
    const [open, setOpen] = useState(false);
    const handleSeeMore = (): void => {
        setOpen(true);
    };

    const handleClose = (): void => {
        setOpen(false);
    };

    return (
        <Box mb={{ xs: 2, sm: 0 }} style={{ width: "100%", height: "200px", marginLeft: "10px" }}>
            <Card
                style={{
                    height: "100%",
                    position: "relative",
                    border: "1px solid white",
                    borderRadius: "8px",
                }}
                sx={{
                    cursor: "pointer",
                    "&:hover": {
                        transform: "scale(1.05)",
                        transition: "transform 0.3s",
                    },
                }}
                onClick={handleSeeMore}
            >
                <CardContent
                    sx={{
                        minHeight: "100%",
                        background: "rgba(0, 0, 0, 0.5)", // Gray-black background color
                        border: "1px solid white", // White border
                        borderRadius: "8px", // Border radius
                        padding: "16px", // Padding for content
                    }}
                >
                    <Typography
                        sx={{
                            background: "rgba(0, 0, 0.5, 0.5)",
                            borderRadius: "8px",
                            padding: 1,
                            color: "white",
                        }}
                        variant="body2"
                        color="text.secondary"
                    >
                        {prompt.promptContent?.length > 100
                            ? prompt.promptContent.substring(0, 97) + "..."
                            : prompt.promptContent}
                    </Typography>
                    <Box sx={discoveryCardBox}>
                        {prompt.tags?.map((tag, index) => (
                            <Chip
                                sx={{
                                    ml: 1,
                                    mb: 1,
                                    cursor: "pointer",
                                    "&:hover": {
                                        transform: "scale(1.05)",
                                        transition: "transform 0.3s",
                                    },
                                }}
                                key={index}
                                label={tag}
                                color="secondary"
                                size="small"
                            />
                        ))}
                    </Box>
                </CardContent>
                <Box
                    style={{
                        position: "absolute",
                        bottom: "10px",
                        right: "10px",
                        display: "flex",
                        gap: "4px",
                        overflowX: "auto", // Add this to enable horizontal scrolling
                        maxWidth: "100%", // Add this to prevent horizontal scrolling overflow
                    }}
                >
                    {prompt.tags?.map((tag: string, index: number) => (
                        <Chip
                            sx={{
                                ml: 1,
                                cursor: "pointer",
                                "&:hover": {
                                    transform: "scale(1.05)",
                                    transition: "transform 0.3s",
                                },
                            }}
                            key={index}
                            label={tag}
                            color="secondary"
                            size="small"
                        />
                    ))}
                </Box>
            </Card>
            <PromptDetailsModal
                open={open}
                onClose={handleClose}
                prompt={prompt}
                metrics={metrics}
                instructions={instructions}
                constraints={constraints}
                examples={examples}
            />
        </Box>
    );
};
export default DiscoveryCard;
