import React from "react";

import { CssBaseline, ThemeProvider } from "@mui/material";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import MasterLayout from "./components/master/MasterLayout";
import ElectronAuth from "./pages/layoutless/ElectronAuth";
import ElectronAuthSignOut from "./pages/layoutless/ElectronAuthSignOut";
import MuggleAISignIn from "./pages/layoutless/SignIn";
import MuggleAISignUp from "./pages/layoutless/SignUp";
import Chat from "./pages/main-layout/Chat";
import Discovery from "./pages/main-layout/Discovery";
import Home from "./pages/main-layout/Home";
import Workbench from "./pages/main-layout/Workbench";
import theme from "./themes/theme";

function App(): React.ReactElement {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router>
                <Routes>
                    <Route path="/auth">
                        <Route path="context/:jwt-template?" element={<ElectronAuth />} />
                        <Route path="signout" element={<ElectronAuthSignOut />} />
                        <Route path="signin" element={<MuggleAISignIn />} />
                        <Route path="signup" element={<MuggleAISignUp />} />
                    </Route>
                    <Route path="/" element={<MasterLayout />}>
                        <Route path="" element={<Home />} />
                        <Route path="workbench" element={<Workbench />} />
                        <Route path="chat" element={<Chat />} />
                        <Route path="discovery" element={<Discovery />} />
                    </Route>
                </Routes>
            </Router>
        </ThemeProvider>
    );
}
export default App;
