// The MUI version of ./Chatbox.module.css
import { SxProps, Theme } from "@mui/material";

const inputTextFieldStyle: SxProps<Theme> = {
    backgroundColor: "#fff",
    "& .MuiInputBase-root": {
        height: "50px",
    },
};

const inputButtonStyle: SxProps<Theme> = {
    height: "49px",
};
// maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'
export { inputButtonStyle, inputTextFieldStyle };

