import { SignInButton, SignedIn, SignedOut, UserButton } from "@clerk/clerk-react";
import { dark } from "@clerk/themes";
import { AppBar, Box, Button, Toolbar, Typography } from "@mui/material";
import { type SxProps, type Theme } from "@mui/system";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/logo.png";
import { UserContext } from "../../context/UserContext";
import styles from "./NavigationBar.module.css"; // Import CSS Module

const buttonStyle: SxProps<Theme> = {
    marginRight: "15px",
    color: "inherit",
    display: "block",
};

// handle menu open and close
const NavigationBar = (): React.ReactElement => {
    const { isSignedIn, user } = useContext(UserContext);
    const routes = [
        { path: "/", name: "Home" },
        { path: "/discovery", name: "Discovery" },
        { path: "/workbench", name: "Workbench" },
        { path: "/chat", name: "Chat" },
    ];

    return (
        <AppBar position="static">
            <Toolbar>
                <Box sx={{ flexGrow: 0, display: { md: "flex" } }}>
                    <Button key={"/"} component={Link} to={"/"} sx={buttonStyle}>
                        <img className={styles.img} src={Logo} alt="MUGGLE AI LOGO" />
                    </Button>
                </Box>

                <Box sx={{ flexGrow: 1, display: { md: "flex" } }}>
                    {routes.map((route) => (
                        <Button key={route.path} component={Link} to={route.path} sx={buttonStyle}>
                            {route.name}
                        </Button>
                    ))}
                </Box>

                <Box sx={{ flexGrow: 0, xs: "block" }}>
                    {isSignedIn && (
                        <Typography sx={{ marginRight: "15px" }}>
                            Welcome, <b>{user?.fullName}</b>
                        </Typography>
                    )}
                </Box>

                <Box sx={{ flexGrow: 0, xs: "block" }}>
                    <SignedOut>
                        <SignInButton>
                            <Button sx={buttonStyle}>Sign In</Button>
                        </SignInButton>
                    </SignedOut>
                    <SignedIn>
                        <UserButton afterSignOutUrl="/auth/signin" appearance={{ baseTheme: dark }} />
                    </SignedIn>
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default NavigationBar;
