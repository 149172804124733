// A react component to display a list of tags chips, and allow the user to add new tag or edit existing tags.

import React, { useState } from "react";
import { Box, Chip, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

interface TagsEditBoxProps {
  tags: string[];
  tagsChange: (tags: string[]) => void;
}

const TagsEditBox: React.FC<TagsEditBoxProps> = ({ tags, tagsChange }) => {
  const [newTag, setNewTag] = useState("");

  const handleDeleteTag = (index: number) => {
    const newTags = [...tags];
    newTags.splice(index, 1);
    tagsChange(newTags);
  };

  const handleAddNewTag = () => {
    if (newTag.trim() !== "") {
      tagsChange([...tags, newTag]);
      setNewTag("");
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      handleAddNewTag();
    }
  };

  return (
    <Box
      sx={{
        display: "inline-flex",
        overflow: "auto",
        flexDirection: "row",
        flexWrap: "wrap",
        p: 0.5,
        m: 0,
      }}
    >
      {tags.map((tag, index) => (
        <Chip
          key={index}
          label={tag}
          onDelete={() => handleDeleteTag(index)}
          variant="filled"
          size="small"
          sx={{ m: 0.5 }}
        />
      ))}
      <Box display="flex" alignItems="center">
        <TextField
          label="Add New Tag"
          value={newTag}
          onChange={(e) => setNewTag(e.target.value)}
          onKeyDown={handleKeyPress}
        />
        <AddIcon onClick={handleAddNewTag} />
      </Box>
    </Box>
  );
};
export default TagsEditBox;
