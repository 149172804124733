import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React, { useCallback, useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import Chatbox from "../../components/chat/chat_box/Chatbox";
import PromptActionButtons from "../../components/workbench/prompt_action/PromptActionButtons";
import PromptEditor from "../../components/workbench/prompt_edit/PromptEditor";
import { IPrompt } from "../../models/interfaces/prompt/IPrompt";
import { IAgent } from "../../models/interfaces/workbench/agent/IAgent";
import type { IConversation } from "../../models/interfaces/workbench/chat/IChatSession";
import { type SectionData } from "../../types/SectionData";
import { StringUtils } from "../../utils/string_utils";

import { RedirectToSignIn, SignedIn, SignedOut } from "@clerk/clerk-react";
import { startNewConversation } from "../../components/chat/ManageConversation";
import LoadModal from "../../components/workbench/load_modal/LoadModal";
import { VersionButton } from "../../components/workbench/version_select_model/VersionButton";
import { OpenAIDefaultLLMSettings } from "../../configs/OpenAIDefaultLLMSettings";
import { SocketContext } from "../../context/SocketContext";
import { UserContext } from "../../context/UserContext";
import useSolution from "../../hooks/useSolution";
import { PromptTypeEnum } from "../../models/interfaces/prompt/PromptTypeEnum";
import { IAgentInstance } from "../../models/interfaces/workbench/agent/IAgentInstance";
import { ChatSessionStatusEnum } from "../../models/interfaces/workbench/chat/ChatSessionStatusEnum";
import { LoadEntityTypeEnum } from "../../types/entity/LoadEntityEnum";
import { containerStyle } from "./WorkbenchStyles";

const DEFAULT_ENTITY_TYPE = LoadEntityTypeEnum.Prompt;

const Workbench: React.FC = () => {
    const { socket } = useContext(SocketContext);
    const { isSignedIn, user } = useContext(UserContext);

    const EmptyConversation: IConversation = {
        chatSessionId: "",
        ownerId: "",
        chatHistory: [],
        chatSessionStatus: ChatSessionStatusEnum.Active,
        llmSettings: OpenAIDefaultLLMSettings,
        participantProfiles: [],
    };

    // Handle data passed in from previous page
    const utcNow = Date.now();
    const location = useLocation();
    const searchData = location.state?.searchResult;
    const discoveryData = location.state?.discoveryData;

    const inputEntityType = (searchData?.entityType ||
        discoveryData?.entityType ||
        DEFAULT_ENTITY_TYPE) as LoadEntityTypeEnum;

    const inputSectorsData = searchData?.sector || discoveryData?.sectors || [];

    const inputSolutionId: string =
        inputEntityType === LoadEntityTypeEnum.Solution
            ? searchData?.solutionId || discoveryData?.solution?.solutionId
            : "";

    const inputPromptData =
        searchData !== undefined
            ? ({
                  promptContent: searchData.prompt,
                  promptId: searchData.prompt_id,
                  promptName: "Unknown",
                  promptDescription: "Unknown",
                  promptType: PromptTypeEnum.Prompt,
                  properties: {},
                  tags: [],
                  ownerId: user.id,
                  isPrivate: false,
                  isActive: true,
                  versionId: utcNow,
                  creationTimeUtc: utcNow,
                  creationTimeUtcReadable: new Date(utcNow).toLocaleString(),
                  agentId: searchData.agent_id,
                  solutionId: searchData.solution_id,
              } as IPrompt)
            : discoveryData !== undefined
            ? discoveryData.prompt
            : ({
                  promptId: "",
                  promptName: "",
                  promptContent: "",
                  promptDescription: "",
                  promptType: PromptTypeEnum.Prompt,
                  properties: {},
                  tags: [],
                  ownerId: user.id,
                  isPrivate: false,
                  isActive: true,
                  versionId: utcNow,
                  creationTimeUtc: utcNow,
                  creationTimeUtcReadable: new Date(utcNow).toLocaleString(),
                  agentId: "",
                  solutionId: "",
              } as IPrompt);

    const [entityType, setEntityType] = useState<LoadEntityTypeEnum>(
        StringUtils.IsStringUndefinedNullOrEmpty(inputEntityType)
            ? LoadEntityTypeEnum.Prompt
            : (inputEntityType as LoadEntityTypeEnum)
    );

    // For prompt input
    const [currentPrompt, setCurrentPrompt] = useState<IPrompt>(inputPromptData);

    // Active agent instances
    const [activeAgentInstances, setActiveAgentInstances] = useState<IAgentInstance[]>([]);

    const setCurrentPromptWithHtmlFreePromptContent = useCallback(
        (textContainsHtmlLineBreak: string, allowHtml: boolean = false) => {
            setCurrentPrompt((prev) => {
                return {
                    ...prev,
                    promptContent: allowHtml
                        ? textContainsHtmlLineBreak
                        : StringUtils.GetHtmlFreePromptContent(textContainsHtmlLineBreak),
                };
            });
        },
        []
    );

    // For section data
    const [sectionData, setSectionData] = useState<SectionData[]>(inputSectorsData);
    const [conversation, setConversation] = useState<IConversation>(EmptyConversation);

    // Keep agent prompt def cached
    const [currentAgent, setCurrentAgent] = useState<IAgent>(); // Current active agent displaying prompt
    const [selectedAgents, setSelectedAgents] = useState<{
        [key: string]: IAgent;
    }>({});

    const { solution, setSolution } = useSolution(inputSolutionId);

    const startConversationAsync = async (agentList: { [agentId: string]: number }): Promise<void> => {
        if (socket === undefined) return;
        const returnConversation: IConversation = await startNewConversation({
            socket: socket,
            systemPrompt: getPromptOutput(),
            userId: user.id,
            agentList: agentList,
            isButlerRequest: false,
        });
        setConversation(returnConversation);
    };

    const getPromptOutput = () => {
        const sectionStrings = sectionData.map(({ sectionName, sectionElement }) => {
            const sentences = sectionElement.map((e) => e.sentence).join(", ");
            return `${sectionName}: ${sentences}`;
        });
        const sectionDataString = sectionStrings.join("\t");
        const promptOutput = StringUtils.GetHtmlFreePromptContent(
            `${currentPrompt.promptContent}\n${sectionDataString}`
        );
        return promptOutput;
    };

    // LoadModal state
    const [isLoadModalOpen, setIsLoadModalOpen] = useState(false);
    const handleLoadModalOpen = () => {
        setIsLoadModalOpen(true);
    };
    const [isAgentPrompt, setIsAgentPrompt] = React.useState(
        !StringUtils.IsStringUndefinedNullOrEmpty(currentAgent?.agentId)
    );

    const resetStates = () => {
        setCurrentPrompt(inputPromptData);
        setSectionData(inputSectorsData);
        setConversation(EmptyConversation);
        setCurrentAgent(undefined);
        setSelectedAgents({});
        setIsAgentPrompt(false);
        setActiveAgentInstances([]);
    };
    if (!isSignedIn) return <RedirectToSignIn />;
    return (
        <Container maxWidth="xl" sx={containerStyle}>
            <SignedOut>
                <RedirectToSignIn />
            </SignedOut>
            <SignedIn>
                <Grid container>
                    <Grid item xs={16}>
                        <Grid container justifyContent="center">
                            {/* main content display */}
                            <Grid item xs={16} sm={10}>
                                <Grid container sx={{ alignItems: "center" }}>
                                    <Grid item sm={6}>
                                        <Box display="flex" flexDirection="row" justifyContent="flex-start">
                                            <Typography variant="h3" sx={{ mx: 1 }}>
                                                Build {entityType}
                                            </Typography>
                                            <VersionButton
                                                props={{
                                                    isAgentPrompt: isAgentPrompt,
                                                    setSelectedAgents: setSelectedAgents,
                                                    currentAgent: currentAgent,
                                                    setCurrentAgent: setCurrentAgent,
                                                    currentPrompt: currentPrompt,
                                                    setCurrentPrompt: setCurrentPrompt,
                                                    userId: user.id,
                                                    chatSessionId: conversation.chatSessionId,
                                                    entityType: entityType,
                                                    currentSolution: solution,
                                                    setCurrentSolution: setSolution,
                                                }}
                                            />
                                        </Box>
                                        {/* <StartElectronButton userId={user.id} /> */}
                                    </Grid>
                                    <Grid item sm={6}>
                                        <Box display="flex" flexDirection="revert" justifyContent="flex-end">
                                            <Button
                                                sx={{ mx: 1 }}
                                                variant="contained"
                                                color="secondary"
                                                onClick={handleLoadModalOpen}
                                            >
                                                Open...
                                            </Button>
                                            <Button
                                                sx={{ ml: 1 }}
                                                color="error"
                                                variant="contained"
                                                onClick={resetStates}
                                            >
                                                Clear Page
                                            </Button>
                                        </Box>
                                        <LoadModal
                                            props={{
                                                userId: user.id,
                                                isLoadModalOpen: isLoadModalOpen,
                                                setIsLoadModalOpen: setIsLoadModalOpen,
                                                currentSolution: solution,
                                                setCurrentSolution: setSolution,
                                                currentPrompt: currentPrompt,
                                                setCurrentPrompt: setCurrentPrompt,
                                                currentAgent: currentAgent,
                                                setCurrentAgent: setCurrentAgent,
                                                selectedAgents: selectedAgents,
                                                setSelectedAgents: setSelectedAgents,
                                                socket: socket,
                                                chatSessionId: conversation.chatSessionId,
                                                isAgentPrompt: isAgentPrompt,
                                                setIsAgentPrompt: setIsAgentPrompt,
                                                entityType: entityType,
                                                setEntityType: setEntityType,
                                            }}
                                        ></LoadModal>
                                    </Grid>
                                </Grid>
                                <Box>
                                    <PromptEditor
                                        initialSectionData={sectionData}
                                        initialPromptId={currentPrompt.promptId}
                                        promptContent={currentPrompt.promptContent}
                                        setPromptContent={setCurrentPromptWithHtmlFreePromptContent}
                                        handleSectionDataChange={setSectionData}
                                    />
                                </Box>
                                <Box>
                                    <PromptActionButtons
                                        startNewConversation={startConversationAsync}
                                        userId={user.id}
                                        userName={user.username}
                                        currentPrompt={currentPrompt}
                                        setCurrentPrompt={setCurrentPrompt}
                                        currentAgent={currentAgent}
                                        setCurrentAgent={setCurrentAgent}
                                        selectedAgents={selectedAgents}
                                        setSelectedAgents={setSelectedAgents}
                                        isAgentPrompt={isAgentPrompt}
                                        setIsAgentPrompt={setIsAgentPrompt}
                                        entityType={entityType}
                                        currentSolution={solution}
                                        setCurrentSolution={setSolution}
                                    />
                                </Box>
                                <hr />
                                <Grid container>
                                    {/* Output content */}
                                    <Grid item xs={9}>
                                        <Typography variant="h3">Chat with {entityType}</Typography>
                                    </Grid>
                                    <Chatbox
                                        socket={socket}
                                        userId={user.id}
                                        userName={user.username}
                                        conversation={conversation}
                                        setConversation={setConversation}
                                        getPrompt={getPromptOutput}
                                        startNewConversationAsync={startConversationAsync}
                                        selectedAgents={selectedAgents}
                                        setSelectedAgents={setSelectedAgents}
                                        activeAgentInstances={activeAgentInstances}
                                        setActiveAgentInstances={setActiveAgentInstances}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </SignedIn>
        </Container>
    );
};

export default Workbench;
