import { PromptFilterDisplayTypeEnum } from "./PromptFilterSectionTypeEnum";
import { PromptFilterType } from "./PromptFilterType";

export class PromptFilter {
  [key: string]: any;
  static target_model: PromptFilterType = {
    field_name: "target_model",
    label: "Target Model",
    display_type: PromptFilterDisplayTypeEnum.Option,
  };
  static interaction_type: PromptFilterType = {
    field_name: "interaction_type",
    label: "Interaction Type",
    display_type: PromptFilterDisplayTypeEnum.Option,
  };
  static shot_type: PromptFilterType = {
    field_name: "shot_type",
    label: "Shot Type",
    display_type: PromptFilterDisplayTypeEnum.Option,
  };
  static role_type: PromptFilterType = {
    field_name: "role_type",
    label: "Role Type",
    display_type: PromptFilterDisplayTypeEnum.Option,
  };
  static domain_type: PromptFilterType = {
    field_name: "domain_type",
    label: "Domain Type",
    display_type: PromptFilterDisplayTypeEnum.Option,
  };
  static complexity_level: PromptFilterType = {
    field_name: "complexity_level",
    label: "Complexity Level",
    display_type: PromptFilterDisplayTypeEnum.Option,
  };
  static task_type: PromptFilterType = {
    field_name: "task_type",
    label: "Task Type",
    display_type: PromptFilterDisplayTypeEnum.Option,
  };
  static properties: PromptFilterType = {
    field_name: "properties",
    label: "Properties",
    display_type: PromptFilterDisplayTypeEnum.Option,
  };
  static tags: PromptFilterType = {
    field_name: "tags",
    label: "Tags",
    display_type: PromptFilterDisplayTypeEnum.Option,
  };
  static popularity: PromptFilterType = {
    field_name: "popularity",
    label: "Popularity",
    display_type: PromptFilterDisplayTypeEnum.Range,
    range: [0, 100],
  };
  static timestamp: PromptFilterType = {
    field_name: "timestamp",
    label: "Timestamp",
    display_type: PromptFilterDisplayTypeEnum.Range,
    range: [0, 100],
  };
}
