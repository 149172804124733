import { SxProps, Theme } from "@mui/material";

const modalBoxStyle: SxProps<Theme> = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  backgroundColor: "background.paper",
  border: "2px solid #000",
  boxShadow: "24",
  padding: "4",
  maxHeight: "80%",
  overflow: "auto",
};

const agentList: SxProps = {
  paddingLeft: "10px",
  overflowY: "scroll",
  maxHeight: "300px",
};
export { agentList, modalBoxStyle };
