// A react component to support electron's auth operation to clerk.

import { SignedIn, SignedOut, SignInButton } from "@clerk/clerk-react";
import { Button, Container } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/UserContext";

const ElectronAuth: React.FC = () => {
    const { isLoaded, isSignedIn, authContext } = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [authData, setAuthData] = useState(authContext);
    useEffect(() => {
        setLoading(true);

        if (!isLoaded || !isSignedIn || authContext === undefined) {
            console.log("UserContext is not loaded.", isLoaded, isSignedIn, authContext);
            setLoading(false);
            return;
        }
        setAuthData(authContext);
        setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoaded, isSignedIn, authContext]);

    if (loading) return <p>Loading...</p>;
    return (
        <Container
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center", // Modified line
                height: "60vh", // Modified line
            }}
        >
            <SignedIn>
                <h1 style={{ textAlign: "center", fontSize: "2rem", color: "white" }}>
                    You have been signed in successfully. Welcome back {authContext.user.fullName}!
                </h1>
            </SignedIn>
            <SignedOut>
                <h1 style={{ textAlign: "center", fontSize: "2rem", color: "white" }}>
                    You have been signed out successfully. Thank you for using Muggle AI.
                </h1>
                <SignInButton>
                    <Button variant="contained" color="warning">
                        Sign In Again
                    </Button>
                </SignInButton>
            </SignedOut>
            <div id="auth-data" style={{ display: "none" }}>
                {JSON.stringify(authData)}
            </div>
        </Container>
    );
};

export default ElectronAuth;
