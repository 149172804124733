import { Socket } from "socket.io-client";
import { OpenAIDefaultLLMSettings } from "../../configs/OpenAIDefaultLLMSettings";
import * as ConversationEvent from "../../models/interfaces/events/conversation_events";
import { ChatSessionRequestType } from "../../models/interfaces/workbench/chat/ChatSessionRequestType";
import { ChatSessionStatusEnum } from "../../models/interfaces/workbench/chat/ChatSessionStatusEnum";
import { IConversation } from "../../models/interfaces/workbench/chat/IChatSession";
import { ILLMMessage } from "../../models/interfaces/workbench/chat/ILLMMessage";
import { IMessage } from "../../models/interfaces/workbench/chat/IMessage";
import { IOpenAILLMSettings } from "../../models/interfaces/workbench/llm/IOpenAILLMSettings";
import { StringUtils } from "../../utils/string_utils";

export const startNewConversation = async ({
    socket,
    systemPrompt,
    userId,
    llmSettings,
    agentList,
    isButlerRequest,
}: {
    socket: Socket | undefined;
    systemPrompt: string;
    userId: string;
    llmSettings?: IOpenAILLMSettings;
    agentList?: { [agentId: string]: number };
    isButlerRequest: boolean;
}): Promise<IConversation> => {
    const now = Date.now();
    const sysPromptMsg: IMessage = {
        messageId: null,
        llmMessage: {
            role: "system",
            content: systemPrompt,
            name: "system",
        } as ILLMMessage,
        timestamp: null,
        fromUserId: userId,
        toUserIds: ["*"],
        llmSettings: null,
        creationTime: now,
        chatSessionId: "",
    };

    const newConversationReq: ChatSessionRequestType = {
        fromUserId: userId,
        chatSystemPrompt: sysPromptMsg,
        agentList: agentList ?? {},
        chatHistory: [],
        participants: [],
        participantProfiles: [],
        llmSettings: llmSettings ?? OpenAIDefaultLLMSettings,
        chatSessionStatus: ChatSessionStatusEnum.Active,
        chatSessionId: undefined,
        llmChatHistory: null,
        isButlerRequest: isButlerRequest,
    };

    if (socket === undefined) throw new Error("Socket is undefined");

    const data = await socket.emitWithAck(ConversationEvent.CREATE, {
        chatRequest: newConversationReq,
        callback: (data: any, err: any) => {
            if (err) {
                throw new Error(`Error: ${err}`);
            }
            console.log(`Conversation created: [${JSON.stringify(data)}]`);
            return data;
        },
    });

    if (data == null || data === undefined) {
        throw new Error(
            `Failed to start new conversation... Error: returned data is null or undefined.`
        );
    }

    if (data.error) {
        throw new Error(
            `Failed to start new conversation... Error: ${data?.error}`
        );
    }

    if (StringUtils.IsStringUndefinedNullOrEmpty(data.chatSessionId)) {
        throw new Error(
            "Failed to start new conversation...No session ID returned."
        );
    }

    const returnConversation: IConversation = {
        chatSessionId: data.chatSessionId,
        chatHistory: data.chatHistory,
        ownerId: data.fromUserId,
        llmSettings: data.llmSettings,
        chatSessionStatus: data.chatSessionStatus,
        participantProfiles: data.participantProfiles,
    };
    return returnConversation;
};

// Send a new chat message to server
export const sendMessage = async ({
    socket,
    userName,
    chatSessionId,
    userId,
    userInputText,
    sendTo,
}: {
    socket: Socket | undefined;
    userName: string;
    chatSessionId: string;
    userId: string;
    userInputText: string;
    sendTo: string;
}): Promise<IMessage> => {
    let msg: IMessage = {} as IMessage;
    if (StringUtils.IsStringUndefinedNullOrEmpty(userInputText)) return msg;

    try {
        const now = Date.now();
        if (socket === undefined) throw new Error("Socket is undefined");

        // Render the user message on local UI first
        const userMsg: IMessage = {
            messageId: null,
            llmMessage: {
                role: "user",
                content: userInputText,
                name: userName,
            } as ILLMMessage,
            timestamp: null,
            fromUserId: userId,
            toUserIds: [sendTo],
            llmSettings: null,
            creationTime: now,
            chatSessionId: chatSessionId,
        };

        msg = await socket.emitWithAck(ConversationEvent.NEW_MESSAGE, {
            chatSessionId: chatSessionId,
            newMsg: userMsg,
            callback: (data: IMessage, err: any) => {
                if (err) {
                    throw new Error(`Error: ${err}`);
                }
                console.log(`Message sent: [${JSON.stringify(data)}]`);
                return data;
            },
        });
    } catch (error) {
        console.error("An error occurred:", error);
    }
    return msg;
};

export const killChat = async ({
    socket,
    chatSessionId,
}: {
    socket: Socket | undefined;
    chatSessionId: string;
}): Promise<void> => {
    try {
        if (socket === undefined) throw new Error("Socket is undefined");

        const msgReq = {
            chatSessionId: chatSessionId,
        };

        socket.emit(
            ConversationEvent.KILL,
            msgReq,
            (callback: any, error: any) => {
                if (error) throw new Error(error);
                if (callback != null) {
                    console.log(
                        `Message sent successfully: Response ${JSON.stringify(
                            callback
                        )}`
                    );
                }
            }
        );
    } catch (error) {
        throw error;
    }
};
