import { SxProps, Theme } from "@mui/material";

export const modalBoxStyle: SxProps<Theme> = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  backgroundColor: "background.paper", // This should be a valid color value, e.g. "#fff"
  border: "2px solid #000",
  boxShadow: 24,
  padding: 4,
};
