import { Box, Grid, Typography } from "@mui/material";
import { titleStyle } from "../../pages/main-layout/DiscoveryStyles";

export const Banner: React.FC = () => {
    return (
        <Grid container justifyContent="center">
            <Grid item xs={12} sm={8}>
                <Box>
                    <Typography variant="h2" sx={titleStyle}>
                        Find You Scenario
                    </Typography>
                </Box>
            </Grid>
        </Grid>
    );
};
