import { Box } from "@mui/material";
import React from "react";
import { IChatParticipant } from "../../../models/interfaces/workbench/chat/IChatParticipant";
import { IMessage } from "../../../models/interfaces/workbench/chat/IMessage";
import { MessageTypeEnum } from "../../../models/interfaces/workbench/chat/MessageTypeEnum";
import { actionMessageStyle } from "./ChatMessagesStyle";

interface IChatMessageAgentActionProp {
    chatParticipantProfiles: Map<string, IChatParticipant>;
    message: IMessage;
    index: number;
}

export const ChatMessageAgentAction: React.FC<IChatMessageAgentActionProp> = ({
    chatParticipantProfiles,
    message,
    index,
}: IChatMessageAgentActionProp) => {
    return (
        <Box key={index} sx={actionMessageStyle}>
            {message.messageType === MessageTypeEnum.AgentAction ? (
                <Box>
                    <Box component="span" sx={{ fontWeight: "bold" }}>
                        {
                            chatParticipantProfiles.get(message.fromUserId)
                                ?.participantName
                        }
                    </Box>{" "}
                    is calling action on{" "}
                    <Box component="span" sx={{ fontWeight: "bold" }}>
                        {
                            chatParticipantProfiles.get(message.toUserIds[0])
                                ?.participantName
                        }
                    </Box>
                    ...
                </Box>
            ) : (
                // NOTE: This is tricky. The returned msg is from the action performer to the action initiator
                <Box>
                    <Box component="span" sx={{ fontWeight: "bold" }}>
                        {
                            chatParticipantProfiles.get(message.fromUserId)
                                ?.participantName
                        }
                    </Box>{" "}
                    is talking to{" "}
                    <Box component="span" sx={{ fontWeight: "bold" }}>
                        {
                            chatParticipantProfiles.get(message.toUserIds[0])
                                ?.participantName
                        }
                    </Box>
                    ...
                </Box>
            )}
        </Box>
    );
};
