import { useAuth } from "@clerk/clerk-react";
import { Box } from "@mui/material";
import * as React from "react";
import { IPrompt } from "../../../models/interfaces/prompt/IPrompt";
import { ISolution } from "../../../models/interfaces/solution/ISolution";
import { ISolutionPromptView } from "../../../models/interfaces/solution/ISolutionPromptView";
import { IAgent } from "../../../models/interfaces/workbench/agent/IAgent";
import { LoadEntityTypeEnum } from "../../../types/entity/LoadEntityEnum";
import { GetBatchAgentDefinitionByIds } from "../../agent/ManageAgent";
import {
    GetSolutionOptions,
    ListSolutionVersionsUrl,
    resetSolutionVersion,
} from "../../solution/ManageSolution";
import { VersionTimeline } from "./VersionTimeline";

interface SolutionVersionTimelineProps {
    props: {
        selectedSolution: ISolution | undefined;
        setSelectedSolution: React.Dispatch<
            React.SetStateAction<ISolution | undefined>
        >;
        currentSolution: ISolution | undefined;
        setCurrentSolution: React.Dispatch<
            React.SetStateAction<ISolution | undefined>
        >;
        setCurrentPrompt: React.Dispatch<React.SetStateAction<IPrompt>>;
        setCurrentAgent: React.Dispatch<
            React.SetStateAction<IAgent | undefined>
        >;
        setSelectedAgents: React.Dispatch<
            React.SetStateAction<{ [agentId: string]: IAgent }>
        >;
        isSelectVersionModalOpen: boolean;
        setIsSelectVersionModalOpen: React.Dispatch<
            React.SetStateAction<boolean>
        >;
        userId: string;
    };
}

const SolutionVersionTimeline: React.FC<SolutionVersionTimelineProps> = ({
    props,
}) => {
    const { getToken } = useAuth();

    const fetchAvailableVersions = async () => {
        if (props.currentSolution === undefined) return [] as ISolution[];
        const resp = await fetch(
            ListSolutionVersionsUrl(
                props.userId,
                props.currentSolution.solutionId
            ),
            GetSolutionOptions((await getToken()) ?? "")
        );

        if (!resp.ok) throw new Error("API request failed: " + resp.text);

        const data: ISolution[] = await resp.json();
        return data;
    };

    const selectSolutionVersion = async (
        targetSolution?: ISolution
    ): Promise<void> => {
        if (
            targetSolution === undefined ||
            props.currentSolution?.solutionId === targetSolution.solutionId
        )
            return; // No change

        const solutionPromptObj: ISolutionPromptView =
            await resetSolutionVersion({
                solutionId: targetSolution.solutionId,
                versionId: targetSolution.versionId,
            });

        if (solutionPromptObj.agents !== undefined) {
            const agentDefs = await GetBatchAgentDefinitionByIds(
                solutionPromptObj.agents,
                (await getToken()) ?? ""
            );
            props.setSelectedAgents(agentDefs);
        }

        const solObj: ISolution = {
            ...solutionPromptObj,
            solutionSystemPrompt: {
                [solutionPromptObj.solutionSystemPrompt.promptId]:
                    solutionPromptObj.solutionSystemPrompt.versionId,
            },
            agents: solutionPromptObj.agents ?? {},
        };

        props.setCurrentSolution(solObj);
        props.setCurrentPrompt(solutionPromptObj.solutionSystemPrompt);
        props.setCurrentAgent(undefined);
    };

    return (
        <Box id="solution-select">
            <VersionTimeline
                props={{
                    entity: {
                        entityType: LoadEntityTypeEnum.Solution,
                        currentEntity: props.currentSolution,
                        setCurrentEntity: props.setCurrentSolution,
                        getCurrentEntityId: () =>
                            props.currentSolution?.solutionId,
                        getCurrentEntityName: () =>
                            props.currentSolution?.solutionName,
                        getCurrentEntityVersionId: () =>
                            props.currentSolution?.versionId,
                        fetchAvailableVersions: fetchAvailableVersions,
                        submitSelectVersion: selectSolutionVersion,
                    },
                    isSelectVersionModalOpen: props.isSelectVersionModalOpen,
                    setIsSelectVersionModalOpen:
                        props.setIsSelectVersionModalOpen,
                    userId: props.userId,
                }}
            />
        </Box>
    );
};

export { SolutionVersionTimeline };
