import SearchIcon from "@mui/icons-material/Search";
import {
    Box,
    FormControl,
    InputAdornment,
    InputLabel,
    ListSubheader,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import React from "react";

import { LoadEntityTypeEnum } from "../../../../types/entity/LoadEntityEnum";

interface LoadOptionSelectProps {
    props: {
        entity: {
            type: LoadEntityTypeEnum;
            selectedOptionId: string;
            setSelectedOptionId: React.Dispatch<React.SetStateAction<string>>;
            setSearchText: React.Dispatch<React.SetStateAction<string>>;
            getOptionName: (selectedOptionId: string) => string;
            getDisplayedOptions: () => {
                id: string;
                displayName: string;
            }[];
        };
    };
}

const LoadOptionSelect: React.FC<LoadOptionSelectProps> = ({ props }) => {
    return (
        <Box>
            <FormControl fullWidth>
                <InputLabel id="search-select-label">
                    {`Available ${props.entity.type}s`}
                </InputLabel>
                <Select
                    // Disables auto focus on MenuItems and allows TextField to be in focus
                    MenuProps={{ autoFocus: false }}
                    labelId="search-select-label"
                    id="search-select"
                    value={props.entity.selectedOptionId}
                    label={`Available ${props.entity.type}s`}
                    onChange={(e) =>
                        props.entity.setSelectedOptionId(e.target.value)
                    }
                    onClose={() => props.entity.setSearchText("")}
                    // This prevents rendering empty string in Select's value
                    // if search text would exclude currently selected option.
                    renderValue={() =>
                        props.entity.getOptionName(
                            props.entity.selectedOptionId
                        )
                    }
                >
                    {/* TextField is put into ListSubheader so that it doesn't
            act as a selectable item in the menu
            i.e. we can click the TextField without triggering any selection.*/}
                    <ListSubheader>
                        <TextField
                            size="small"
                            // Autofocus on textfield
                            autoFocus
                            placeholder="Type to search..."
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            onChange={(e) =>
                                props.entity.setSearchText(e.target.value)
                            }
                            onKeyDown={(e) => {
                                if (e.key !== "Escape") {
                                    // Prevents autoselecting item while typing (default Select behaviour)
                                    e.stopPropagation();
                                }
                            }}
                        />
                    </ListSubheader>
                    {props.entity.getDisplayedOptions()?.map(
                        (
                            option: {
                                id: string;
                                displayName: string;
                            },
                            index: number
                        ) => (
                            <MenuItem key={index} value={option.id}>
                                {option.displayName}
                            </MenuItem>
                        )
                    )}
                </Select>
            </FormControl>
        </Box>
    );
};

export default LoadOptionSelect;
