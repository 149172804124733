// A layout component that shows NavigationBar.tsx on top, and a centralized content area below.

import React from "react";
import { Outlet } from "react-router-dom";
import NavigationBar from "./NavigationBar";

const MasterLayout = () => {
    return (
        <React.Fragment>
            <NavigationBar />
            <Outlet />
        </React.Fragment>
    );
};

export default MasterLayout;
