// A constant file of all socket event names
const CONNECT = "connect";
const DISCONNECTING = "disconnecting";
const DISCONNECT = "disconnect";
const CONNECT_ERROR = "connect_error";
const RECONNECT = "reconnect";
const RECONNECT_ATTEMPT = "reconnect_attempt";

export {
    CONNECT,
    CONNECT_ERROR,
    DISCONNECT,
    DISCONNECTING,
    RECONNECT,
    RECONNECT_ATTEMPT,
};
