import { Grid } from "@mui/material";
import { IPrompt } from "../../models/interfaces/prompt/IPrompt";
import { PromptFilterOptionDataType } from "../../types/discovery/PromptFilterOptionType";
import DiscoveryCard from "../DiscoveryCard";

interface PromptCardDisplayProps {
    cards: PromptFilterOptionDataType[];
    setCards: React.Dispatch<
        React.SetStateAction<PromptFilterOptionDataType[]>
    >;
    sort: string;
    setSort: React.Dispatch<React.SetStateAction<string>>;
}

export const PromptCardDisplay: React.FC<PromptCardDisplayProps> = ({
    cards,
    sort,
}: PromptCardDisplayProps) => {
    const getSortedCards = (): PromptFilterOptionDataType[] => {
        return [...cards].sort((a, b) => b[sort] - a[sort]);
    };

    return (
        <Grid container spacing={2}>
            {/* cards */}
            {getSortedCards().map((card, index) => (
                <Grid item xs={12} sm={4} key={index}>
                    <DiscoveryCard
                        key={index}
                        prompt={
                            {
                                promptContent: card.promptContent,
                            } as unknown as IPrompt
                        }
                        examples={card.sectors.examples}
                        constraints={card.sectors.constraints}
                        metrics={card.sectors.metrics}
                        instructions={card.sectors.instructions}
                    />
                </Grid>
            ))}
        </Grid>
    );
};
