import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { IAgent } from "../../../../models/interfaces/workbench/agent/IAgent";
import { agentStatusBoxStyle, agentStatusStyle } from "../ChatboxStyle";

interface SelectAgentListProps {
    selectedAgents: { [key: string]: IAgent };
    setIsSelectAgentModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SelectAgentList: React.FC<SelectAgentListProps> = ({
    selectedAgents,
    setIsSelectAgentModalOpen,
}) => {
    return (
        <Grid container sx={agentStatusBoxStyle} direction="column">
            <Grid item xs={1}>
                <Typography>
                    <b>Selected Agents</b>
                </Typography>
            </Grid>
            {selectedAgents === undefined ||
            selectedAgents == null ||
            Object.keys(selectedAgents).length === 0 ? (
                <Grid item xs={1} sx={agentStatusStyle}>
                    No Selected Agents
                </Grid>
            ) : (
                Object.keys(selectedAgents).map((agentId) => (
                    <Grid item xs={1} key={agentId} sx={agentStatusStyle}>
                        <Typography align="left" fontSize="medium">
                            <CheckCircleIcon
                                fontSize="small"
                                color="action"
                                sx={{
                                    verticalAlign: "middle",
                                    fontSize: "small",
                                    mr: 1,
                                }}
                            />
                            {selectedAgents[agentId].agentName}
                        </Typography>
                    </Grid>
                ))
            )}
            <Grid item xs={1} sx={agentStatusStyle}>
                <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={() => {
                        setIsSelectAgentModalOpen(true);
                    }}
                >
                    Invite Agent
                </Button>
            </Grid>
        </Grid>
    );
};

export default SelectAgentList;
