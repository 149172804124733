import { IOpenAILLMSettings } from "../models/interfaces/workbench/llm/IOpenAILLMSettings";

export const OpenAIDefaultLLMSettings: IOpenAILLMSettings = {
    // gpt-4-32k-0613
    // gpt-4-1106-preview
    // gpt-3.5-turbo-1106
    // gpt-4-0125-preview
    model: "gpt-4-0125-preview",
    maxTokens: 4096,
    temperature: 0.3,
    topP: 1,
    frequencyPenalty: 0,
    presencePenalty: 0,
    stop: [],
    stream: false,
    responseFormat: { type: "text" },
};
