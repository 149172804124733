import {
    Box,
    Button,
    List,
    ListItem,
    ListItemText,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import React from "react";

import { useAuth } from "@clerk/clerk-react";
import { REACT_APP_BACKEND_PROTECTED_URL } from "../../../../configs/config";
import { IPrompt } from "../../../../models/interfaces/prompt/IPrompt";
import { PromptTypeEnum } from "../../../../models/interfaces/prompt/PromptTypeEnum";
import { ISolution } from "../../../../models/interfaces/solution/ISolution";
import { ISolutionPromptView } from "../../../../models/interfaces/solution/ISolutionPromptView";
import { ILLMMessage } from "../../../../models/interfaces/workbench/chat/ILLMMessage";
import { IMessage } from "../../../../models/interfaces/workbench/chat/IMessage";
import { ISavePromptModalProp } from "../../../../models/interfaces/workbench/page_prop/ISavePromptModalProp";
import GetGlobalHeader from "../../../../utils/auth_utils";
import { StringUtils } from "../../../../utils/string_utils";
import PropertiesEditBox from "./PropertiesEditBox";
import TagsEditBox from "./TagsEditBox";

const SAVE_SOLUTION_URL = `${REACT_APP_BACKEND_PROTECTED_URL}/solutionPrompt`;
const POST_SOLUTION_OPTIONS = (
    solutionObj: ISolutionPromptView,
    authToken?: string
) =>
    GetGlobalHeader({
        method: "POST",
        authToken: authToken,
        body: JSON.stringify(solutionObj),
    });

const SaveSolutionView: React.FC<ISavePromptModalProp> = ({
    props,
}: ISavePromptModalProp) => {
    const { getToken } = useAuth();

    const handleSaveSolution = async (): Promise<void> => {
        try {
            if (
                props.solution === undefined ||
                Object.keys(props.solution).length === 0
            )
                return;

            const agentList = Object.fromEntries(
                Object.values(props.selectedAgents).map((agent) => [
                    agent.agentId,
                    agent.versionId,
                ])
            );

            const promptObj: IPrompt = {
                ...props.prompt,
                ownerId: props.userId,
                promptType: PromptTypeEnum.Solution,
                solutionId: props.solution.solutionId || "",
                promptDescription: props.solution.solutionDescription || "",
                promptName: props.solution.solutionName || "",
                properties: props.solution.properties || {},
                tags: props.solution.tags || [],
            };

            const newSolutionObj: ISolutionPromptView = {
                ...props.solution,
                ownerId: props.userId,
                agents: agentList,
                solutionSystemPrompt: promptObj,
                firstMsg: props.solution.firstMsg,
            };

            console.log(`Saving solution ${JSON.stringify(newSolutionObj)}`);

            const response = await fetch(
                SAVE_SOLUTION_URL,
                POST_SOLUTION_OPTIONS(newSolutionObj, (await getToken()) ?? "")
            );
            const data: ISolutionPromptView = await response.json();
            if (response.ok === false)
                throw new Error("API request failed: " + response.statusText);
            const retSol: ISolution = {
                solutionId: data.solutionId,
                solutionName: data.solutionName,
                solutionDescription: data.solutionDescription,
                properties: data.properties,
                tags: data.tags,
                ownerId: data.ownerId,
                solutionSystemPrompt: {
                    [data.solutionSystemPrompt.promptId]:
                        data.solutionSystemPrompt.versionId,
                },
                firstMsg: data.firstMsg,
                agents: data.agents ?? {},
                isActive: data.isActive,
                isPrivate: data.isPrivate,
                versionId: data.versionId,
                creationTimeUtc: data.creationTimeUtc,
                creationTimeUtcReadable: data.creationTimeUtcReadable,
            };

            props.setPrompt(data.solutionSystemPrompt);
            props.setSolution(retSol);
            console.log(`Solution saved successfully ${JSON.stringify(data)} `);

            props.setIsSavePromptModalOpen(false);
        } catch (error) {
            console.error("An error occurs", error);
        }
    };

    return (
        <Typography id="modal-description">
            {/* Modal Content */}
            <Box display="flex" height={500}>
                {/* Left Half Area */}
                <Box
                    flex="1"
                    mr={2}
                    overflow="auto"
                    pr={2}
                    borderRight="1px solid gray"
                >
                    <Box flex="1" overflow="auto">
                        <Typography variant="subtitle1">
                            Solution Name
                        </Typography>
                        <TextField
                            label="Give your solution a name *"
                            variant="filled"
                            placeholder="Your solution's name"
                            onChange={(e) =>
                                props.setSolution((prev) => {
                                    const ret = {
                                        ...prev,
                                        solutionName: e.target.value,
                                    } as ISolution;
                                    return ret;
                                })
                            }
                            value={props.solution?.solutionName}
                        />
                    </Box>
                    <Box flex="1" overflow="auto" mt={2}>
                        <Typography variant="subtitle1">
                            Solution Prompt
                        </Typography>
                        <Stack direction="row">
                            <Box
                                sx={{
                                    whiteSpace: "pre-wrap",
                                    overflowX: "clip",
                                }}
                            >
                                {StringUtils.GetHtmlFreePromptContent(
                                    props.prompt.promptContent
                                ) || ""}
                            </Box>
                        </Stack>
                    </Box>
                    <Box flex="1" overflow="auto" mt={2}>
                        <Typography variant="subtitle1">
                            Selected Agents
                        </Typography>
                        <List dense={true}>
                            {Object.values(props.selectedAgents).map(
                                (agent) => {
                                    return (
                                        <ListItem>
                                            <ListItemText
                                                primary={`${agent.agentName}: version(${agent.versionId})`}
                                            />
                                        </ListItem>
                                    );
                                }
                            )}
                        </List>
                    </Box>
                    <Box flex="1" overflow="auto" mt={2}>
                        <Typography variant="subtitle1">
                            First Message
                        </Typography>
                        <TextField
                            id="solution-first-msg"
                            variant="outlined"
                            placeholder="Write the first message to send to the group chat after the system prompt here..."
                            onChange={(e) =>
                                props.setSolution((prev) => {
                                    const ret = {
                                        ...prev,
                                        firstMsg: {
                                            llmMessage: {
                                                role: "user",
                                                content: e.target.value,
                                                name: props.userName,
                                            } as ILLMMessage,
                                            fromUserId: props.userId,
                                            // 01/18/2024 TODO: Figure out how to send out msg to a specific agent
                                        } as IMessage,
                                    } as ISolution;
                                    return ret;
                                })
                            }
                            value={props.solution?.firstMsg?.llmMessage.content}
                            multiline
                            fullWidth
                        />
                    </Box>
                </Box>
                {/* Right Half Area */}
                <Box
                    flex="1"
                    ml={2}
                    overflow="auto"
                    display="flex"
                    flexDirection="column"
                >
                    <Box flex="1">
                        <Typography variant="subtitle1">Description</Typography>
                        <TextField
                            id="solution-description"
                            label="Description"
                            variant="outlined"
                            placeholder="Your solution's description"
                            onChange={(e) =>
                                props.setSolution((prev) => {
                                    const ret = {
                                        ...prev,
                                        solutionDescription: e.target.value,
                                    } as ISolution;
                                    return ret;
                                })
                            }
                            value={props.solution?.solutionDescription}
                            multiline
                            fullWidth
                        />
                    </Box>
                    {/* Properties Section */}
                    <Box flex="1" mt={2}>
                        <Typography variant="subtitle1">Properties</Typography>
                        <PropertiesEditBox
                            properties={props.solution?.properties || {}}
                            propertiesChange={(newProperties) => {
                                props.setSolution((prev) => {
                                    const ret = {
                                        ...prev,
                                        properties: newProperties,
                                    } as ISolution;
                                    return ret;
                                });
                            }}
                        />
                    </Box>
                    {/* Tags Section */}
                    <Box flex="1" mt={2}>
                        <Typography variant="subtitle1">Tags</Typography>
                        <TagsEditBox
                            tags={props.solution?.tags || []}
                            tagsChange={(newTags) => {
                                props.setSolution((prev) => {
                                    const ret = {
                                        ...prev,
                                        tags: newTags,
                                    } as ISolution;
                                    return ret;
                                });
                            }}
                        />
                    </Box>
                </Box>
            </Box>
            {/* Buttom Buttons Area */}
            <Box mt={2} display="flex" justifyContent="flex-end">
                <Box padding={2}>
                    <Button
                        variant="contained"
                        color="warning"
                        onClick={() => props.setIsSavePromptModalOpen(false)}
                    >
                        Cancel
                    </Button>
                </Box>
                <Box padding={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSaveSolution}
                    >
                        Save Solution
                    </Button>
                </Box>
            </Box>
        </Typography>
    );
};

export default SaveSolutionView;
