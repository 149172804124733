import { SxProps, Theme } from "@mui/material";

export const chatMessageBoxStyle: SxProps<Theme> = {
    backgroundColor: "#fff",
    height: "70vh",
    width: "100%",
    maxWidth: "1150px",
    overflowY: "auto",
    overflowX: "hidden",
    lineHeight: "1.5",
    fontFamily: "sans-serif",
    fontSize: "large",
    paddingY: "10px",
    paddingX: "20px",
    whiteSpace: "pre-wrap",
    overflowWrap: "break-word",
};

export const chatMessageStyle: SxProps<Theme> = {
    marginY: "15px",
    padding: "5px",
};

export const chatMessageBodyStyle: SxProps<Theme> = {
    marginTop: "5px",
};

export const actionMessageStyle: SxProps<Theme> = {
    opacity: "0.5",
    marginBottom: "2px",
    paddingY: "1px",
    paddingX: "5px",
    fontSize: "small",
};
