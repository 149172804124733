import { Container, Grid } from "@mui/material";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import Sidefilter from "../../components/discovery/SideFilter";
import { REACT_APP_API_KEY, REACT_APP_BACKEND_PROMPT_INDEX_URL } from "../../configs/config";
import { StringUtils } from "../../utils/string_utils";

import _ from "lodash";
import { useLocation } from "react-router-dom";
import { Banner } from "../../components/discovery/Banner";
import { PromptCardDisplay } from "../../components/discovery/PromptCardDisplay";
import { SortBar } from "../../components/discovery/SortBar";
import { HomeSearchForwardStateType } from "../../types/HomeSearchForwardStateType";
import { PromptFilter } from "../../types/discovery/PromptFilter";
import { PromptFilterOptionDataType } from "../../types/discovery/PromptFilterOptionType";
import { PromptFilterDisplayTypeEnum } from "../../types/discovery/PromptFilterSectionTypeEnum";
import { PromptFilterType } from "../../types/discovery/PromptFilterType";
import { SideFilterStateType } from "../../types/discovery/SideFilterStateType";

const Discovery: React.FC = () => {
    const location = useLocation();
    const passedInSearchState = location.state as undefined | HomeSearchForwardStateType;

    const fetchRef = useRef<any>(null);

    // sorted by
    const [sort, setSort] = useState("popularity"); // default sort by popularity
    const [processedData, setProcessedData] = useState<PromptFilterOptionDataType[]>([]);
    const [cards, setCards] = useState<PromptFilterOptionDataType[]>([]);
    const [filterStates, setFilterStates] = React.useState<SideFilterStateType>(
        Object.fromEntries(
            Object.values(PromptFilter).map((filter: PromptFilterType) => [
                filter.field_name,
                filter.display_type === PromptFilterDisplayTypeEnum.Range
                    ? [
                          Math.min(...processedData.map((pd) => pd[filter.field_name])),
                          Math.max(...processedData.map((pd) => pd[filter.field_name])),
                      ]
                    : [...new Set(processedData.map((pd) => pd[filter.field_name]))],
            ])
        )
    );

    async function fetchData(tags: string[]): Promise<void> {
        try {
            console.log("send", tags);
            const tagQuery = tags.join(" AND ");
            const fetchUrl = `${REACT_APP_BACKEND_PROMPT_INDEX_URL}&search=${encodeURIComponent(tagQuery)}`;
            const res = await axios.get(fetchUrl, {
                headers: {
                    "api-key": REACT_APP_API_KEY,
                },
            });
            const dataArray = res.data.value;
            let processedData: PromptFilterOptionDataType[] = [];

            if (Array.isArray(dataArray)) {
                processedData = dataArray.map((item) => {
                    const processedItem: PromptFilterOptionDataType = {
                        target_model: item.target_model,
                        interaction_type: item.interaction_type,
                        shot_type: item.shot_type,
                        role_type: item.role_type,
                        promptContent: item.prompt,
                        domain_type: StringUtils.IsStringUndefinedNullOrEmpty(item.domain_type)
                            ? []
                            : item.domain_type.split(","),
                        complexity_level: item.complexity_level,
                        task_type: item.task_type,
                        prompt_content: item.prompt,
                        sectors: {
                            examples: StringUtils.IsStringUndefinedNullOrEmpty(item.sectors) ? [] : item.examples,
                            instructions: StringUtils.IsStringUndefinedNullOrEmpty(item.sectors)
                                ? []
                                : item.instructions,
                            metrics: StringUtils.IsStringUndefinedNullOrEmpty(item.sectors) ? [] : item.metrics,
                            constraints: StringUtils.IsStringUndefinedNullOrEmpty(item.sectors) ? [] : item.constraints,
                        },
                        properties: StringUtils.IsStringUndefinedNullOrEmpty(item.properties) ? {} : item.properties,
                        tags: StringUtils.IsStringUndefinedNullOrEmpty(item.tags) ? [] : item.tags, // handle null tags
                        popularity: StringUtils.IsStringUndefinedNullOrEmpty(item.popularity) ? 0 : item.popularity, // handle null popularity
                        timestamp: item.timestamp,
                        relevance: StringUtils.IsStringUndefinedNullOrEmpty(item.relevance) ? 0 : item.relevance, // handle null relevance
                    };
                    return processedItem;
                });
                setProcessedData(processedData);
                setCards(processedData);
                console.log("data received", dataArray);
            } else {
                console.error("Parsed data is not an array: " + JSON.stringify(dataArray));
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        fetchRef.current = _.debounce(async (value: string[]) => {
            await fetchData(value);
        }, 500);
        fetchRef.current(
            passedInSearchState === undefined || passedInSearchState == null
                ? [""]
                : `search.in(${passedInSearchState.filter_name}, ${passedInSearchState.filter_value?.join("', '")})`
        );
    }, [filterStates, passedInSearchState]);

    // useEffect(() => {
    //   const initialFetch = async (): Promise<void> => {
    //     try {
    //       await fetchData([""]);
    //     } catch (error) {
    //       console.error(error);
    //     }
    //   };

    //   initialFetch();
    // });
    return (
        <Container maxWidth="xl">
            <Grid container>
                <Grid item xs={12}>
                    <Banner />
                </Grid>
                <Grid item sm={16} xs={12}>
                    <SortBar sort={sort} setSort={setSort} />
                    <Grid container justifyContent="center">
                        <Grid item xs={12} sm={2}>
                            <Sidefilter
                                processedData={processedData}
                                setProcessedData={setProcessedData}
                                filterStates={filterStates}
                                setFilterStates={setFilterStates}
                            />
                        </Grid>

                        {/* main content display */}
                        <Grid item xs={12} sm={10}>
                            <PromptCardDisplay cards={cards} setCards={setCards} sort={sort} setSort={setSort} />
                        </Grid>
                    </Grid>
                </Grid>
                {/* Other pages element... */}
            </Grid>
        </Container>
    );
};

export default Discovery;
