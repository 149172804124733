import { Helmet } from "react-helmet";

export default function GoogleAnalyticsHeader() {
    return (
        <Helmet>
            <script
                async
                src="https://www.googletagmanager.com/gtag/js?id=G-K8RRP1QWH4"
            ></script>
            <script>
                {`window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());

                    gtag('config', 'G-K8RRP1QWH4');`}
            </script>
        </Helmet>
    );
}
