import { type SxProps, type Theme } from "@mui/system";

export const titleStyle: SxProps<Theme> = {
  variant: "h1",
  color: "#fff",
  textAlign: "center",
  mb: 10,
};

export const containerStyle: SxProps<Theme> = {
  maxWidth: "1920px",
  py: 5,
};

export const textFieldStyle: SxProps<Theme> = {
  variant: "outlined",
  color: "primary",
  bgcolor: "#fff",
  mt: "50px",
  width: "100%",
};

export const buttonBoxStyle: SxProps<Theme> = {
  display: "flex",
  justifyContent: "center",
  mb: 3,
};

export const buttonStyle: SxProps<Theme> = {
  variant: "contained",
};

export const sortedByStyle: SxProps<Theme> = {
  variant: "h3",
  color: "#fff",
  textAlign: "end",
  mb: 2,
};

export const cardBoxStyle: SxProps<Theme> = {
  display: "flex",
  flexDirection: { xs: "column", sm: "row" },
  justifyContent: "space-between",
  m: 3,
  gap: 2,
};

export const modalBoxStyle: SxProps<Theme> = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 6,
};
export const modalBuildButton: SxProps<Theme> = {
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "center",
  width: "100%",
  p: 2,
  marginTop: "40%",
  background: "#1976d2", // Material-UI primary color
  //   color: 'white'
};

export const modalCloseButton: SxProps<Theme> = {
  p: 2,
  mt: 0,
  mb: 3,
  marginTop: "0",
  background: "primary", // Material-UI primary color
  //   color: 'white'
};

export const cardStyle: SxProps<Theme> = {
  width: "100%",
  height: "120px",
  backgroundColor: "#f0f0f0",
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
  borderRadius: "8px",
  padding: "20px",
  cursor: "pointer",
};

export const sendButton: SxProps<Theme> = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",

  // p: 2,
  marginTop: "10%",
  background: "#1976d2", // Material-UI primary color
  color: "white",
};

export const input: SxProps<Theme> = {
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "center",
  width: "100%",
  mt: 2,
  mb: 2,
  marginBot: "100px",
  background: "white", // Material-UI primary color
  color: "white",
};

export const outputStyle: SxProps<Theme> = {
  width: "auto",
  height: "250px",
  overflow: "auto",
  padding: 2,
};

export const gridStyle: SxProps<Theme> = {
  display: "flex",
  alignItems: "center",
};

export const metricStyle: SxProps<Theme> = {
  backgroundColor: "yellow",
  padding: "10px",
  marginBottom: "5px",
  width: "auto",
  height: "50px",
  overflow: "auto",
};

