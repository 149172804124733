import { useAuth, useSession, useUser } from "@clerk/clerk-react";
import { useEffect, useState } from "react";
import { REACT_APP_CLERK_SIGN_OUT_REDIRECT_URL } from "../configs/config";
import IElectronAuthContext from "../models/context/IElectronAuthContext";
import { UserContext } from "./UserContext";

const UserContextProvider = ({ children }: { children: React.ReactNode }) => {
    const { isLoaded, isSignedIn, sessionId, getToken, signOut } = useAuth(); // Only single session
    const { session } = useSession();
    const { user } = useUser();
    const [token, setToken] = useState("");
    const [firebaseToken, setFirebaseToken] = useState("");
    const [authContext, setAuthContext] = useState<IElectronAuthContext>({
        isSignedIn: isSignedIn ?? false,
        user: {
            id: user?.id as string,
            username: user?.username as string,
            fullName: user?.fullName as string,
            email: user?.primaryEmailAddress?.emailAddress as string,
        },
        session: {
            id: sessionId as string,
            expireAt: session?.expireAt as Date,
            status: session?.status as string,
            lastActiveAt: session?.lastActiveAt as Date,
        },
        activeAuthToken: "",
        activeFirebaseToken: "",
    });

    const signOutFunction = async (redirect_url?: string) => {
        setAuthContext({
            isSignedIn: false,
            user: {
                id: "",
                username: "",
                fullName: "",
                email: "",
            },
            session: {
                id: "",
                expireAt: new Date(),
                status: "",
                lastActiveAt: new Date(),
            },
            activeAuthToken: "",
            activeFirebaseToken: "",
        });
        await signOut({
            sessionId: sessionId as string,
            redirectUrl: redirect_url ?? REACT_APP_CLERK_SIGN_OUT_REDIRECT_URL,
        });
    };

    const getTokenFunctionAsync = async (jwtTemplate?: string) => {
        const newToken = await getToken({ template: jwtTemplate || undefined }); // NOTE: use customized jwt template to support studio auth to firebase
        setToken(newToken as string);
        return newToken ?? undefined;
    };

    useEffect(() => {
        if (isLoaded && isSignedIn) {
            // Check if token still valid, avoid flooding clerk with token renewal requests
            if (session?.status === "active" && session?.expireAt > new Date() && token !== "") {
                console.log("Session is still valid.");
                return;
            }

            console.log("Renewing token.", session, user);
            getTokenFunctionAsync().then((t) => {
                if (t !== undefined && t !== null && t !== "") {
                    setToken(t);
                }
                setAuthContext((prevAuthContext: IElectronAuthContext) => {
                    return {
                        ...prevAuthContext,
                        activeAuthToken: t as string,
                    };
                });
            });
            getTokenFunctionAsync("integration_firebase").then((t) => {
                if (t !== undefined && t !== null && t !== "") {
                    setFirebaseToken(t);
                }
                setAuthContext((prevAuthContext: IElectronAuthContext) => {
                    return {
                        ...prevAuthContext,
                        activeFirebaseToken: t as string,
                    };
                });
            });
            let ac: IElectronAuthContext = {
                isSignedIn: isSignedIn,
                user: {
                    id: user?.id as string,
                    username: user?.username as string,
                    fullName: user?.fullName as string,
                    email: user?.primaryEmailAddress?.emailAddress as string,
                },
                session: {
                    id: sessionId as string,
                    expireAt: session?.expireAt as Date,
                    status: session?.status as string,
                    lastActiveAt: session?.lastActiveAt as Date,
                },
                activeAuthToken: token,
                activeFirebaseToken: firebaseToken,
            };
            setAuthContext(ac);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSignedIn, session?.status]);

    return (
        <UserContext.Provider
            value={{
                isLoaded: isLoaded,
                isSignedIn: isSignedIn ?? false,
                user: {
                    id: user?.id as string,
                    username: user?.username as string,
                    fullName: user?.fullName as string,
                    email: user?.primaryEmailAddress?.emailAddress as string,
                },
                session: {
                    id: sessionId as string,
                    expireAt: session?.expireAt as Date,
                    status: session?.status as string,
                    lastActiveAt: session?.lastActiveAt as Date,
                },
                authContext: authContext as IElectronAuthContext,
                getToken: getTokenFunctionAsync,
                signOut: signOutFunction,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export default UserContextProvider;
