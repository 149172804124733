import { StringUtils } from "./string_utils";

export class ParamUtils {
  static ThrowIfParamUndefinedOrNull<T>({
    paramName,
    paramValue,
    errorMsg = `ParamUtils.checkParamUndefinedOrNull: ${paramName} is undefined or null`,
  }: {
    paramName: string;
    paramValue: T;
    errorMsg: string;
  }): void {
    if (typeof paramValue === "string") {
      if (StringUtils.IsStringUndefinedNullOrEmpty(paramValue)) {
        throw Error(errorMsg);
      }
      return;
    }
    if (paramValue === undefined || paramValue === null) {
      throw Error(errorMsg);
    }
  }
}
