import { SignIn } from "@clerk/clerk-react";
import { useSearchParams } from "react-router-dom";

export default function MuggleAISignIn() {
    const query = useSearchParams();
    const [param] = query;
    if (param !== undefined && param !== null && param.get("redirect_url") !== "") {
        return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                <SignIn signUpUrl="/auth/signup" forceRedirectUrl={param.get("redirect_url")} />
            </div>
        );
    }
    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
            <SignIn signUpUrl="/auth/signup" />
        </div>
    );
}
