import CloseIcon from "@mui/icons-material/Close";

import React from "react";
import { IAgent } from "../../../models/interfaces/workbench/agent/IAgent";

import { Box, Fade, IconButton, Modal, Typography } from "@mui/material";
import { IPrompt } from "../../../models/interfaces/prompt/IPrompt";
import { ISolution } from "../../../models/interfaces/solution/ISolution";
import { LoadEntityTypeEnum } from "../../../types/entity/LoadEntityEnum";
import { AgentVersionTimeline } from "./AgentVersionTimeline";
import { PromptVersionTimeline } from "./PromptVersionTimeline";
import { modalStyle } from "./SelectVersionModalStyles";
import { SolutionVersionTimeline } from "./SolutionVersionTimeline";

interface SelectVersionModalProps {
    props: {
        isAgentPrompt: boolean;
        entityType: LoadEntityTypeEnum;
        userId: string;
        isSelectVersionModalOpen: boolean;
        setIsSelectVersionModalOpen: React.Dispatch<
            React.SetStateAction<boolean>
        >;
        currentSolution: ISolution | undefined;
        setCurrentSolution: React.Dispatch<
            React.SetStateAction<ISolution | undefined>
        >;
        setSelectedAgents: React.Dispatch<
            React.SetStateAction<{ [key: string]: IAgent }>
        >;
        currentAgent: IAgent | undefined;
        setCurrentAgent: React.Dispatch<
            React.SetStateAction<IAgent | undefined>
        >;
        currentPrompt: IPrompt;
        setCurrentPrompt: React.Dispatch<React.SetStateAction<IPrompt>>;
    };
}

const SelectVersionModal: React.FC<SelectVersionModalProps> = ({
    props,
}: SelectVersionModalProps) => {
    const [checkedSolution, setCheckedSolution] = React.useState(
        props.currentSolution
    );

    const [checkedAgent, setCheckedAgent] = React.useState(props.currentAgent);
    const [checkedPrompt, setCheckedPrompt] = React.useState(
        props.currentPrompt
    );

    const renderEntityContentPage = () => {
        switch (props.entityType) {
            case LoadEntityTypeEnum.Solution:
                return (
                    <SolutionVersionTimeline
                        props={{
                            selectedSolution: checkedSolution,
                            setSelectedSolution: setCheckedSolution,
                            isSelectVersionModalOpen:
                                props.isSelectVersionModalOpen,
                            setIsSelectVersionModalOpen:
                                props.setIsSelectVersionModalOpen,
                            currentSolution: props.currentSolution,
                            setCurrentSolution: props.setCurrentSolution,
                            setCurrentAgent: props.setCurrentAgent,
                            setCurrentPrompt: props.setCurrentPrompt,
                            setSelectedAgents: props.setSelectedAgents,
                            userId: props.userId,
                        }}
                    />
                );
            case LoadEntityTypeEnum.Agent:
                return (
                    <AgentVersionTimeline
                        props={{
                            checkedAgent: checkedAgent,
                            setCheckedAgent: setCheckedAgent,
                            isSelectVersionModalOpen:
                                props.isSelectVersionModalOpen,
                            setIsSelectVersionModalOpen:
                                props.setIsSelectVersionModalOpen,
                            currentAgent: props.currentAgent,
                            setCurrentAgent: props.setCurrentAgent,
                            currentPrompt: props.currentPrompt,
                            setCurrentPrompt: props.setCurrentPrompt,
                            userId: props.userId,
                        }}
                    />
                );
            case LoadEntityTypeEnum.Prompt:
                return (
                    <PromptVersionTimeline
                        props={{
                            selectedPrompt: checkedPrompt,
                            setSelectedPrompt: setCheckedPrompt,
                            isSelectVersionModalOpen:
                                props.isSelectVersionModalOpen,
                            setIsSelectVersionModalOpen:
                                props.setIsSelectVersionModalOpen,
                            currentPrompt: props.currentPrompt,
                            setCurrentPrompt: props.setCurrentPrompt,
                            userId: props.userId,
                        }}
                    />
                );
            default:
                return <Box>No available version.</Box>;
        }
    };

    return (
        <Modal
            open={props.isSelectVersionModalOpen}
            onClose={() => props.setIsSelectVersionModalOpen(false)}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Fade in={props.isSelectVersionModalOpen}>
                <Box sx={modalStyle}>
                    <Typography id="modal-title" variant="h6" component="h2">
                        Rollback to a previous version
                    </Typography>
                    <IconButton
                        style={{ position: "absolute", right: 8, top: 8 }}
                        onClick={() => props.setIsSelectVersionModalOpen(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Box className="modal-content">
                        {renderEntityContentPage()}
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
};

export default SelectVersionModal;
