// A constant file of all conversation event names
const CREATE = "conversation:create";
const READ = "conversation:read";
const UPDATE_AG_IN_CHAT = "conversation:updateAgentInstanceInChat";
const NEW_MESSAGE = "conversation:newmsg";
const ON_MSG = "conversation:onmsg";
const KILL = "conversation:kill";
const JOIN = "conversation:join";
const LEAVE = "conversation:leave";
const LIST_ACTIVE_AGENTS = "conversation:listActiveAgents";
const ACTIVATE_SELECTED_AGENTS = "conversation:activateSelectedAgents";

export {
    ACTIVATE_SELECTED_AGENTS,
    CREATE,
    JOIN,
    KILL,
    LEAVE,
    LIST_ACTIVE_AGENTS,
    NEW_MESSAGE,
    ON_MSG,
    READ,
    UPDATE_AG_IN_CHAT,
};
