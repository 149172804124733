import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import React from "react";

import { useAuth } from "@clerk/clerk-react";
import { REACT_APP_BACKEND_PROTECTED_URL } from "../../../../configs/config";
import { IPrompt } from "../../../../models/interfaces/prompt/IPrompt";
import { PromptTypeEnum } from "../../../../models/interfaces/prompt/PromptTypeEnum";
import { ISavePromptModalProp } from "../../../../models/interfaces/workbench/page_prop/ISavePromptModalProp";
import GetGlobalHeader from "../../../../utils/auth_utils";
import { StringUtils } from "../../../../utils/string_utils";
import PropertiesEditBox from "./PropertiesEditBox";
import TagsEditBox from "./TagsEditBox";

const SavePromptView: React.FC<ISavePromptModalProp> = ({ props }) => {
    const { getToken } = useAuth();

    const handleSavePrompt = async (): Promise<void> => {
        try {
            const newPromptObj: IPrompt = {
                ...props.prompt,
                ownerId: props.userId,
                promptType: PromptTypeEnum.Prompt,
            };

            console.log(`Saving prompt ${JSON.stringify(newPromptObj)}`);

            const response = await fetch(
                `${REACT_APP_BACKEND_PROTECTED_URL}/prompt`,
                GetGlobalHeader({
                    method: "POST",
                    body: JSON.stringify(newPromptObj),
                    authToken: (await getToken()) ?? "",
                })
            );
            const data = await response.json();
            if (response.ok === false)
                throw new Error("API request failed: " + response.statusText);

            console.log(`Prompt saved successfully ${JSON.stringify(data)} `);

            props.setIsSavePromptModalOpen(false);
            if (data) props.setPrompt(data as unknown as IPrompt);
        } catch (error) {
            console.error("An error occurs", error);
        }
    };

    return (
        <Typography id="modal-description">
            {/* Modal Content */}
            <Box display="flex" height={500}>
                {/* Left Half Area */}
                <Box
                    flex="1"
                    mr={2}
                    overflow="auto"
                    pr={2}
                    borderRight="1px solid gray"
                >
                    <Box flex="1" overflow="auto">
                        <Typography variant="subtitle1">Prompt Name</Typography>
                        <TextField
                            label="Give your prompt a name *"
                            variant="filled"
                            placeholder="Your prompt's name"
                            onChange={(e) =>
                                props.setPrompt((prev) => {
                                    return {
                                        ...prev,
                                        promptName: e.target.value,
                                    };
                                })
                            }
                            value={props.prompt.promptName}
                        />
                    </Box>
                    <Box flex="1" overflow="auto" mt={2}>
                        <Typography variant="subtitle1">Prompt</Typography>
                        <Stack direction="row">
                            <Box
                                sx={{
                                    whiteSpace: "pre-wrap",
                                    overflowX: "clip",
                                }}
                            >
                                {StringUtils.GetHtmlFreePromptContent(
                                    props.prompt.promptContent
                                ) || ""}
                            </Box>
                        </Stack>
                    </Box>
                </Box>
                {/* Right Half Area */}
                <Box
                    flex="1"
                    ml={2}
                    overflow="auto"
                    display="flex"
                    flexDirection="column"
                >
                    <Box flex="1">
                        <Typography variant="subtitle1">Description</Typography>
                        <TextField
                            id="prompt-description"
                            label="Description"
                            variant="outlined"
                            placeholder="Your prompt's description"
                            onChange={(e) =>
                                props.setPrompt((prev) => {
                                    return {
                                        ...prev,
                                        promptDescription: e.target.value,
                                    };
                                })
                            }
                            value={props.prompt.promptDescription}
                            multiline
                            fullWidth
                        />
                    </Box>
                    {/* Properties Section */}
                    <Box flex="1" mt={2}>
                        <Typography variant="subtitle1">Properties</Typography>
                        <PropertiesEditBox
                            properties={props.prompt.properties}
                            propertiesChange={(newProperties) => {
                                props.setPrompt((prev) => {
                                    return {
                                        ...prev,
                                        properties: newProperties,
                                    };
                                });
                            }}
                        />
                    </Box>
                    {/* Tags Section */}
                    <Box flex="1" mt={2}>
                        <Typography variant="subtitle1">Tags</Typography>
                        <TagsEditBox
                            tags={props.prompt.tags}
                            tagsChange={(newTags) => {
                                props.setPrompt((prev) => {
                                    return { ...prev, tags: newTags };
                                });
                            }}
                        />
                    </Box>
                </Box>
            </Box>
            {/* Buttom Buttons Area */}
            <Box mt={2} display="flex" justifyContent="flex-end">
                <Box padding={2}>
                    <Button
                        variant="contained"
                        color="warning"
                        onClick={() => props.setIsSavePromptModalOpen(false)}
                    >
                        Cancel
                    </Button>
                </Box>
                <Box padding={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSavePrompt}
                    >
                        Save Prompt
                    </Button>
                </Box>
            </Box>
        </Typography>
    );
};

export default SavePromptView;
