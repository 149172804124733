import { AgentFunctionRoleEnum } from "./AgentFunctionRoleEnum";

export class OpenAIAgentConstants {
    static DefaultName = "OpenAI";
    static DefaultRole = AgentFunctionRoleEnum.Assistant;
    static DefaultId = "00000000-0000-0000-0000-000000000000";
    static DefaultSystemPrompt = "";

    static DefaultButlerName = "MuggleAI Assistant";
    static DefaultButlerRole = AgentFunctionRoleEnum.Assistant;
    static DefaultButlerId = "11111111-1111-1111-1111-111111111111";
    static DefaultButlerSystemPrompt = "";
}
