import {
    Timeline,
    TimelineConnector,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator,
} from "@mui/lab";
import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
} from "@mui/material";
import * as React from "react";
import { useEffect } from "react";
import { EntityVersionTimelineType } from "../../../types/entity/EntityVersionTimelineType";
import { StringUtils } from "../../../utils/string_utils";

interface IVersionTimelineProps<T> {
    props: {
        entity: EntityVersionTimelineType<T>;
        isSelectVersionModalOpen: boolean;
        setIsSelectVersionModalOpen: React.Dispatch<
            React.SetStateAction<boolean>
        >;
        userId: string;
    };
}
function VersionTimeline<T>({ props }: IVersionTimelineProps<T>) {
    const [availableVersionRequest, setAvailableVersionRequest] =
        React.useState({
            loading: false,
            availableOptions: {} as { [key: number]: T },
            error: null as unknown as Error,
        });

    const [selectedVersionId, setSelectedVersionId] = React.useState(
        props.entity.getCurrentEntityVersionId() ?? 0
    );

    const onClose = () => {
        console.log(
            `Current entity version: ${props.entity.getCurrentEntityVersionId()}`
        );
        setSelectedVersionId(props.entity.getCurrentEntityVersionId() ?? 0);
        props.setIsSelectVersionModalOpen(false);
    };

    useEffect(() => {
        const fetchAvailableVersions = async () => {
            try {
                if (
                    props.isSelectVersionModalOpen === false ||
                    props.entity.currentEntity === undefined ||
                    StringUtils.IsStringUndefinedNullOrEmpty(
                        props.entity.getCurrentEntityId()
                    )
                )
                    return;

                setAvailableVersionRequest((prevState) => {
                    return {
                        ...prevState,
                        loading: true,
                    };
                });

                const data: T[] =
                    (await props.entity.fetchAvailableVersions()) ?? [];

                setAvailableVersionRequest((prevState) => {
                    return {
                        ...prevState,
                        loading: false,
                        availableOptions: Object.fromEntries(
                            data
                                .sort((a: any, b: any) => {
                                    if (a.versionId === undefined) return -1;
                                    if (b.versionId === undefined) return 1;
                                    return a.versionId - b.versionId;
                                })
                                .map((v: any) => [v.versionId, v])
                        ),
                        error: null as unknown as Error,
                    };
                });
                setSelectedVersionId(
                    props.entity.getCurrentEntityVersionId() ?? 0
                );
            } catch (error: Error | any) {
                console.log(error);
                setAvailableVersionRequest((prevState) => {
                    return {
                        ...prevState,
                        availableOptions: {},
                        error: error,
                    };
                });
            } finally {
                setAvailableVersionRequest((prevState) => {
                    return {
                        ...prevState,
                        loading: false,
                    };
                });
            }
        };
        if (props.isSelectVersionModalOpen === true) fetchAvailableVersions();
        else onClose();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isSelectVersionModalOpen]);

    const handleSubmitSelectedVersion = async () => {
        try {
            if (props.entity === undefined) return; // No entity to rollback
            if (
                selectedVersionId === undefined ||
                selectedVersionId === props.entity.getCurrentEntityVersionId()
            )
                return; // No version selected

            await props.entity.submitSelectVersion(
                availableVersionRequest.availableOptions[selectedVersionId]
            );
            props.setIsSelectVersionModalOpen(false);
        } catch (error) {
            console.error("An error occurred:", error);
        }
    };

    const handleSelectVersionChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        console.log(`Current selected version: ${event.target.value}`);
        console.log(`selectedVersionId: ${selectedVersionId}`);
        console.log(
            `availableVersions: ${Object.keys(
                availableVersionRequest.availableOptions
            )}`
        );
        setSelectedVersionId(parseInt(event.target.value));
    };

    return (
        <Box id="version-select">
            {availableVersionRequest.loading ? (
                <CircularProgress />
            ) : availableVersionRequest.error != null &&
              availableVersionRequest.error !== undefined ? (
                <Box>
                    Error:{" "}
                    {JSON.stringify(availableVersionRequest.error.message)}
                </Box>
            ) : availableVersionRequest === undefined ||
              availableVersionRequest == null ||
              Object.keys(availableVersionRequest.availableOptions).length ===
                  0 ? (
                <Box>
                    NOTE: Please Select a {props.entity.entityType} First.
                </Box>
            ) : (
                <FormControl component="fieldset">
                    <FormLabel id="version-radio-group">
                        Available Versions of "
                        {props.entity.getCurrentEntityName()}"
                    </FormLabel>

                    <RadioGroup
                        aria-labelledby="version-radio-group"
                        name="controlled-radio-buttons-group"
                        value={selectedVersionId}
                        onChange={handleSelectVersionChange}
                    >
                        <Timeline>
                            {Object.keys(
                                availableVersionRequest.availableOptions
                            ).map((vStr, index) => {
                                const versionId = parseInt(vStr);
                                return (
                                    <TimelineItem key={versionId}>
                                        <TimelineOppositeContent color="text.secondary">
                                            {new Date(
                                                (
                                                    availableVersionRequest
                                                        .availableOptions[
                                                        versionId
                                                    ] as any
                                                ).creationTimeUtc
                                            ).toLocaleString()}
                                        </TimelineOppositeContent>
                                        <TimelineSeparator>
                                            <FormControlLabel
                                                label={props.entity.getCurrentEntityName()}
                                                control={
                                                    <Radio
                                                        size="small"
                                                        value={versionId}
                                                        checked={
                                                            versionId ===
                                                            selectedVersionId
                                                        }
                                                    />
                                                }
                                            />
                                            {index <
                                            Object.keys(
                                                availableVersionRequest.availableOptions
                                            ).length -
                                                1 ? (
                                                <TimelineConnector />
                                            ) : null}
                                        </TimelineSeparator>
                                    </TimelineItem>
                                );
                            })}
                        </Timeline>
                    </RadioGroup>
                </FormControl>
            )}

            <Box mt={2} display="flex" justifyContent="flex-end">
                <Box padding={2}>
                    <Button variant="contained" onClick={onClose}>
                        Back
                    </Button>
                </Box>
                <Box padding={2}>
                    <Button
                        variant="contained"
                        color="success"
                        onClick={handleSubmitSelectedVersion}
                    >
                        Rollback to version
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}

export { VersionTimeline };
