// src/context/SocketContext.js
import { useContext, useEffect, useState } from "react";
import { Socket, io } from "socket.io-client";
import { REACT_APP_BACKEND_SOCKET_URL } from "../configs/config";
import * as SocketEvent from "../models/interfaces/events/socket_events";
import { StringUtils } from "../utils/string_utils";
import { SocketContext } from "./SocketContext";
import { UserContext } from "./UserContext";

const SocketProvider = ({ children }: { children: React.ReactNode }) => {
    const { isSignedIn, getToken, session, user } = useContext(UserContext);
    const sessionId = session?.id;
    const [socket, setSocket] = useState<Socket>();
    const [loading, setLoading] = useState<boolean>(true);

    function setupSocketState(conn: Socket | undefined) {
        if (conn === undefined) return;
        function onConnect(): void {
            if (!conn?.connected) return;
            if (!StringUtils.IsStringUndefinedNullOrEmpty((conn.auth as { authToken: string }).authToken)) {
                console.log(`Connected to socket.io server with auth`);
            } else {
                console.log(`Connected to socket.io server`);
            }
            setLoading(false);
        }

        function onDisconnect(): void {
            if (conn?.connected) return;
            console.log(`Disconnected from socket.io server`);
        }

        function onConnectError(err: Error): void {
            console.log(`connect_error due to ${JSON.stringify(err)}, ${err.message}`);
            console.log(Object.getOwnPropertyDescriptors(err));
        }

        function onReconnect(): void {
            console.log(`Reconnected to socket.io server`);
            setLoading(false);
        }

        function onReconnectAttempt(attempt: number): void {
            console.log(`Reconnect attempt: ${attempt}`);
        }

        conn.on(SocketEvent.CONNECT, onConnect);
        conn.on(SocketEvent.DISCONNECT, onDisconnect);
        conn.on(SocketEvent.CONNECT_ERROR, onConnectError);
        conn.io.on(SocketEvent.RECONNECT, onReconnect);
        conn.io.on(SocketEvent.RECONNECT_ATTEMPT, onReconnectAttempt);

        if (!conn.connected) conn.connect();

        return () => {
            conn.off(SocketEvent.CONNECT, onConnect);
            conn.off(SocketEvent.DISCONNECT, onDisconnect);
            conn.off(SocketEvent.CONNECT_ERROR, onConnectError);
            conn.off(SocketEvent.RECONNECT, onReconnect);
            conn.off(SocketEvent.RECONNECT_ATTEMPT, onReconnectAttempt);
        };
    }

    useEffect(() => {
        if (!isSignedIn) {
            setLoading(false);
            return;
        }
        getToken().then((t) => {
            setLoading(true);
            if (socket !== undefined && socket.connected) {
                socket.disconnect();
            }

            let newConn = io(REACT_APP_BACKEND_SOCKET_URL, {
                auth: {
                    username: user?.username,
                    userId: user?.id,
                    sessionId: sessionId,
                    authToken: t,
                },
                autoConnect: true,
                extraHeaders: {
                    Authorization: t ? `Bearer ${t}` : "",
                },
            });
            setupSocketState(newConn);
            setSocket(newConn);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [session.status, isSignedIn]);

    return (
        <SocketContext.Provider value={{ socket: socket, loading: loading, setLoading: setLoading }}>
            {children}
        </SocketContext.Provider>
    );
};

export default SocketProvider;
