import { Box, Link } from "@mui/material";
import * as React from "react";
import { IPrompt } from "../../../models/interfaces/prompt/IPrompt";
import { ISolution } from "../../../models/interfaces/solution/ISolution";
import { IAgent } from "../../../models/interfaces/workbench/agent/IAgent";
import { LoadEntityTypeEnum } from "../../../types/entity/LoadEntityEnum";
import SelectVersionModal from "./SelectVersionModal";
interface VersionButtonProps {
    props: {
        isAgentPrompt: boolean;
        entityType: LoadEntityTypeEnum;
        setSelectedAgents: React.Dispatch<
            React.SetStateAction<{ [key: string]: IAgent }>
        >;
        currentAgent: IAgent | undefined;
        setCurrentAgent: React.Dispatch<
            React.SetStateAction<IAgent | undefined>
        >;
        currentPrompt: IPrompt;
        setCurrentPrompt: React.Dispatch<React.SetStateAction<IPrompt>>;
        currentSolution: ISolution | undefined;
        setCurrentSolution: React.Dispatch<
            React.SetStateAction<ISolution | undefined>
        >;
        userId: string;
        chatSessionId: string;
    };
}

const VersionButton: React.FC<VersionButtonProps> = ({
    props,
}: VersionButtonProps) => {
    const [open, setOpen] = React.useState(false);

    return (
        <Box display="flex" justifyContent="center" alignItems="center">
            <Link
                component="button"
                variant="button"
                onClick={() =>
                    setOpen((prev) => {
                        console.log(JSON.stringify(props.currentAgent));
                        return !prev;
                    })
                }
                sx={{
                    ml: 1,
                    fontSize: "1.0rem",
                    color: "#000000",
                }}
            >
                Previous Versions
            </Link>
            <SelectVersionModal
                props={{
                    isAgentPrompt: props.isAgentPrompt,
                    entityType: props.entityType,
                    isSelectVersionModalOpen: open,
                    setIsSelectVersionModalOpen: setOpen,
                    currentSolution: props.currentSolution,
                    setCurrentSolution: props.setCurrentSolution,
                    setSelectedAgents: props.setSelectedAgents,
                    currentAgent: props.currentAgent,
                    setCurrentAgent: props.setCurrentAgent,
                    currentPrompt: props.currentPrompt,
                    setCurrentPrompt: props.setCurrentPrompt,
                    userId: props.userId,
                }}
            />
        </Box>
    );
};

export { VersionButton };
