import { Box, Paper } from "@mui/material";
import React from "react";
import { IChatParticipant } from "../../../models/interfaces/workbench/chat/IChatParticipant";
import { IConversation } from "../../../models/interfaces/workbench/chat/IChatSession";
import { ChatMessage } from "./ChatMessage";
import { ChatMessageAgentAction } from "./ChatMessageAgentAction";
import { chatMessageBoxStyle } from "./ChatMessagesStyle";

interface IChatMessageWindowProp {
    conversation: IConversation | undefined;
    chatParticipantProfiles: Map<string, IChatParticipant>;
    endOfChatRef: React.RefObject<HTMLDivElement>;
    showAgentActionDetails: boolean;
}

export const ChatMessages: React.FC<IChatMessageWindowProp> = ({
    conversation,
    chatParticipantProfiles,
    endOfChatRef,
    showAgentActionDetails,
}: IChatMessageWindowProp) => {
    return (
        <Paper elevation={2}>
            <Box sx={chatMessageBoxStyle}>
                {conversation?.chatHistory?.map((message, index) => (
                    <Box component="div" key={index}>
                        {chatParticipantProfiles.get(message.toUserIds[0])
                            ?.participantType ===
                            chatParticipantProfiles.get(message.fromUserId)
                                ?.participantType && !showAgentActionDetails ? (
                            <ChatMessageAgentAction
                                chatParticipantProfiles={
                                    chatParticipantProfiles
                                }
                                message={message}
                                index={index}
                            />
                        ) : (
                            <ChatMessage
                                chatParticipantProfiles={
                                    chatParticipantProfiles
                                }
                                message={message}
                                index={index}
                            />
                        )}
                    </Box>
                ))}
                <Box ref={endOfChatRef} />
            </Box>
        </Paper>
    );
};
