import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
// MUI font
import { ClerkProvider } from "@clerk/clerk-react";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import GoogleAnalyticsHeader from "./components/auth/google_analytics_header";
import * as config from "./configs/config";
import SocketProvider from "./context/SocketContextProvider";
import UserContextProvider from "./context/UserContextProvider";
const CLERK_PUBLISHABLE_KEY = config.REACT_APP_CLERK_PUBLISHABLE_KEY;
if (!CLERK_PUBLISHABLE_KEY) {
    throw new Error("Missing Clerk Publishable Key");
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
    // <React.StrictMode>
    <ClerkProvider publishableKey={CLERK_PUBLISHABLE_KEY}>
        <GoogleAnalyticsHeader />
        <UserContextProvider>
            <SocketProvider>
                <App />
            </SocketProvider>
        </UserContextProvider>
    </ClerkProvider>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
