import { FormControlLabel, Grid, Paper, Switch } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import * as ConversationEvent from "../../../models/interfaces/events/conversation_events";
import { IAgentInstance } from "../../../models/interfaces/workbench/agent/IAgentInstance";
import { IChatParticipant } from "../../../models/interfaces/workbench/chat/IChatParticipant";
import { type IMessage } from "../../../models/interfaces/workbench/chat/IMessage";
import { ParticipantTypeEnum } from "../../../models/interfaces/workbench/chat/ParticipantTypeEnum";
import { type IChatBoxProp } from "../../../models/interfaces/workbench/chatbox/IChatBoxProp";
import { StringUtils } from "../../../utils/string_utils";
import { GetActiveAgents } from "../../agent/ManageAgent";
import SelectAgentModal from "../../workbench/select_agent_modal/SelectAgentModal";
import ChatInput from "./ChatInput";
import { ChatMessages } from "./ChatMessageWindow";
import ActiveAgentList from "./agent_list_box/ActiveAgentList";
import SelectAgentList from "./agent_list_box/SelectAgentList";

const Chatbox: React.FC<IChatBoxProp> = ({
    socket,
    userId,
    userName,
    conversation,
    setConversation,
    getPrompt,
    startNewConversationAsync,
    selectedAgents,
    setSelectedAgents,
    activeAgentInstances,
    setActiveAgentInstances,
}) => {
    const [isSelectAgentModalOpen, setIsSelectAgentModalOpen] = useState(false);
    const [showAgentActionDetails, setShowAgentActionDetails] = useState(false);

    // Reference to the last message in chat box
    const messagesEndRef = useRef<HTMLDivElement>(null);

    const handleToggleAgentActionDetails = () => {
        setShowAgentActionDetails((prev) => !prev);
    };

    useEffect(() => {
        if (
            StringUtils.IsStringUndefinedNullOrEmpty(
                conversation.chatSessionId
            ) ||
            messagesEndRef.current === null ||
            conversation.chatHistory?.length === 0
        )
            return;
        // Scroll to the bottom of the chat box when a new conversation is started
        messagesEndRef.current?.scrollIntoView();
    }, [
        conversation.chatHistory?.length,
        conversation.chatSessionId,
        showAgentActionDetails,
    ]);

    // Keep track of chat participants profiles
    const [chatParticipantProfiles, setChatParticipantProfiles] = useState<
        Map<string, IChatParticipant>
    >(
        new Map<string, IChatParticipant>([
            [
                userId,
                {
                    participantId: userId,
                    participantType: ParticipantTypeEnum.User,
                    participantName: "You",
                },
            ],
        ])
    );

    // Fetch active agents routinely
    useEffect(() => {
        const tick = setInterval(() => {
            try {
                GetActiveAgents({ socket: socket, conversation: conversation })
                    .catch((err) => {
                        throw err;
                    })
                    .then((data) => {});
            } catch (error) {
                console.error("An error occurred:", error);
            }
        }, 20000);
        return () => {
            clearInterval(tick);
        };
    });

    const convertParticipantsProfiles = useCallback(
        (agentInstanceList: IAgentInstance[]) => {
            const newMap = new Map<string, IChatParticipant>([
                // Keep user profile in the map
                [
                    userId,
                    {
                        participantId: userId,
                        participantType: ParticipantTypeEnum.User,
                        participantName: "You",
                    },
                ],
            ]);

            agentInstanceList.forEach((agentInst: IAgentInstance) => {
                if (agentInst.agentId !== undefined) {
                    newMap.set(agentInst.agentId, {
                        participantId: agentInst.agentId,
                        participantType: ParticipantTypeEnum.Agent,
                        participantName: agentInst.agentName,
                    });
                }
            });
            return newMap;
        },
        [userId]
    );

    // Keep chat participant list updated if activeAgents changes
    useEffect(() => {
        setChatParticipantProfiles(
            convertParticipantsProfiles(activeAgentInstances)
        );
    }, [
        activeAgentInstances,
        userId,
        conversation.chatSessionId,
        convertParticipantsProfiles,
    ]);

    // Update chat history when message comes
    useEffect(() => {
        if (socket === undefined) return;
        const messageListener = ({ newMsg }: { newMsg: IMessage }): void => {
            setConversation((prev) => {
                const conv = { ...prev };
                conv.chatHistory.push(newMsg);
                return conv;
            });
        };

        const agInsUpdateListener = (data: IAgentInstance[]) => {
            console.debug(`Agent instances updated: ${data}.`);
            setActiveAgentInstances(data);
        };

        socket.on(ConversationEvent.ON_MSG, messageListener);
        socket.on(ConversationEvent.UPDATE_AG_IN_CHAT, agInsUpdateListener);

        return () => {
            socket.off(ConversationEvent.ON_MSG);
            socket.off(ConversationEvent.UPDATE_AG_IN_CHAT);
        };
    });

    return (
        <Grid container spacing={1}>
            <SelectAgentModal
                props={{
                    userId: userId,
                    isSelectAgentModalOpen: isSelectAgentModalOpen,
                    setIsSelectAgentModalOpen: setIsSelectAgentModalOpen,
                    setConversation: setConversation,
                    selectedAgents: selectedAgents,
                    setSelectedAgents: setSelectedAgents,
                    socket: socket,
                    chatSessionId: conversation.chatSessionId,
                    startNewConversationAsync: startNewConversationAsync,
                    getPrompt: getPrompt,
                }}
            />
            <Grid item xs={12} sx={{ display: "flex", flexDirection: "row" }}>
                <Grid
                    container
                    sx={{ display: "flex", flexDirection: "row" }}
                    spacing={1}
                >
                    <Grid item xs={10} sm={10}>
                        <ChatMessages
                            conversation={conversation}
                            chatParticipantProfiles={chatParticipantProfiles}
                            endOfChatRef={messagesEndRef}
                            showAgentActionDetails={showAgentActionDetails}
                        />
                    </Grid>
                    <Grid item xs={2} sx={{ px: 1 }}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={showAgentActionDetails}
                                    onChange={handleToggleAgentActionDetails}
                                    color="info"
                                />
                            }
                            label="Show Agent Action Details"
                        />
                        <Paper elevation={2}>
                            {/* Active Agents list */}
                            {activeAgentInstances.length > 0 ? (
                                <ActiveAgentList
                                    socket={socket}
                                    chatSessionId={conversation.chatSessionId}
                                    activeAgents={activeAgentInstances}
                                    setIsSelectAgentModalOpen={
                                        setIsSelectAgentModalOpen
                                    }
                                    selectedAgents={selectedAgents}
                                    startNewConversationAsync={
                                        startNewConversationAsync
                                    }
                                />
                            ) : (
                                <SelectAgentList
                                    selectedAgents={selectedAgents}
                                    setIsSelectAgentModalOpen={
                                        setIsSelectAgentModalOpen
                                    }
                                />
                            )}
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
                <ChatInput
                    userId={userId}
                    userName={userName}
                    chatSessionId={conversation.chatSessionId}
                    socket={socket}
                    chatParticipantProfiles={chatParticipantProfiles}
                />
            </Grid>
        </Grid>
    );
};

export default Chatbox;
