import { Grid } from "@mui/joy";
import { Paper } from "@mui/material";
import React, { useCallback } from "react";
import { type SectionData } from "../../../types/SectionData";
import PromptInputBox from "./PromptInputBox";

interface PromptEditorProps {
    initialSectionData: SectionData[];
    initialPromptId: string;
    promptContent: string;
    setPromptContent: (newPrompt: string, allowHtml: boolean) => void;
    handleSectionDataChange: (newData: SectionData[]) => void;
}

const PromptEditor: React.FC<PromptEditorProps> = ({
    initialSectionData,
    initialPromptId,
    promptContent,
    setPromptContent,
    handleSectionDataChange,
}) => {
    // const convertSectorsDataToArray = (
    //   sectorsData: Record<string, SectionElement[]>
    // ): SectionData[] => {
    //   return Object.keys(sectorsData).map((key) => ({
    //     sectionName: key,
    //     sectionElement: sectorsData[key],
    //   }));
    // };

    console.log("Initial prompt id:", initialPromptId);
    // type SectionTitle = "examples" | "instructions" | "metrics" | "constraints";
    // const [initialData, setInitialData] =
    //   useState<SectionData[]>(initialSectionData);

    // useEffect(() => {
    //   async function fetchData(promptId: string): Promise<void> {
    //     try {
    //       const url = REACT_APP_BACKEND_SENTENCE_INDEX_URL;
    //       const response = await axios
    //         .post(
    //           url,
    //           {
    //             search: "*",
    //             select: "*",
    //             filter: `prompt_id eq '${promptId}'`,
    //             orderby: "sector_type",
    //           },
    //           {
    //             headers: {
    //               "Content-Type": "application/json",
    //               "api-key": REACT_APP_API_KEY,
    //             },
    //           }
    //         )
    //         .catch(function (error) {
    //           console.log(error);
    //           throw error;
    //         });
    //       const dataArray = response.data;
    //       console.log("response data: ", dataArray);
    //     } catch (error) {
    //       console.error(error);
    //     }
    //   }
    //   if (initialpromptId) {
    //     fetchData(initialpromptId).catch((err) => {
    //       console.error(err);
    //     });
    //   }
    // }, [initialpromptId]);

    // const handleDataChange = (newData: SectionData[]) => {
    //   console.log("Data has been updated:", newData);
    //   setInitialData(newData);
    //   handleSectionDataChange(newData);
    // };

    const handlePromptChange = useCallback(
        (newPrompt: string) => {
            setPromptContent(newPrompt ?? "", true);
            console.log(`Prompt has been updated: [${newPrompt}]`);
        },
        [setPromptContent]
    );

    // const handleElementsChange = (
    //   sectionTitle: SectionTitle,
    //   newElements: SectionElement[]
    // ) => {
    //   setInitialData((prevData) => {
    //     const oldSection = prevData.find(
    //       (item) => item.sectionName === sectionTitle
    //     );
    //     if (
    //       JSON.stringify(oldSection?.sectionElement) ===
    //       JSON.stringify(newElements)
    //     ) {
    //       return prevData;
    //     }
    //     const newData = [...prevData];
    //     const sectionIndex = newData.findIndex(
    //       (item) => item.sectionName === sectionTitle
    //     );

    //     if (sectionIndex !== -1) {
    //       newData[sectionIndex] = {
    //         ...newData[sectionIndex],
    //         sectionElement: newElements,
    //       };
    //     }

    //     handleDataChange(newData);

    //     return newData;
    //   });
    // };

    return (
        <Grid container spacing={2}>
            <Grid component="div" xs={12}>
                <Paper elevation={2}>
                    <PromptInputBox
                        internalPrompt={promptContent}
                        setInternalPrompt={setPromptContent}
                        sectionData={initialSectionData}
                        onSectionDataChange={handleSectionDataChange}
                        onPromptChange={handlePromptChange}
                    />
                </Paper>
            </Grid>
            {/* <Grid component="div" xs={12}>
        <SectionGrid
          sectorsData={}
          handleElementsChange={handleElementsChange}
          handleSectionDataChange={handleSectionDataChange}
        />
      </Grid> */}
        </Grid>
    );
};

export default PromptEditor;
