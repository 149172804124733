import { Box, Grid } from "@mui/material";
import React from "react";
import SearchInput from "./SearchInput";

const SearchBox: React.FC = () => {
    return (
        <Grid container justifyContent="center">
            <Grid item xs={12} sm={8}>
                <Box mb={2} marginTop={10}>
                    <SearchInput />
                </Box>
                {/* <Box sx={buttonBoxStyle}>
          <LargeButton text="SEARCH" />
        </Box> */}
            </Grid>
        </Grid>
    );
};
export default SearchBox;
