// This file is used to store the environment variables that are used in the application.

// Knowledge index search apis
const REACT_APP_BACKEND_PROMPT_INDEX_URL = process.env.REACT_APP_BACKEND_PROMPT_INDEX_URL as string;
const REACT_APP_BACKEND_SENTENCE_INDEX_URL = process.env.REACT_APP_BACKEND_SENTENCE_INDEX_URL as string;
const REACT_APP_BACKEND_SOLUTION_INDEX_URL = process.env.REACT_APP_BACKEND_SOLUTION_INDEX_URL as string;

// Backend urls
const REACT_APP_BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL as string;
const REACT_APP_BACKEND_PROTECTED_URL = `${REACT_APP_BACKEND_BASE_URL}/protected`;
const REACT_APP_BACKEND_PUBLIC_URL = `${REACT_APP_BACKEND_BASE_URL}/public`;
const REACT_APP_BACKEND_SOCKET_URL = process.env.REACT_APP_BACKEND_SOCKET_URL as string;

// API keys
const REACT_APP_API_KEY = process.env.REACT_APP_API_KEY as string;

// Clerk settings
const REACT_APP_CLERK_PUBLISHABLE_KEY = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY as string;
const REACT_APP_CLERK_SIGN_OUT_REDIRECT_URL = process.env.REACT_APP_CLERK_SIGN_OUT_REDIRECT_URL as string;

// Muggle AI Studio settings
const REACT_APP_STUDIO_SIGN_OUT_REDIRECT_URL = process.env.REACT_APP_STUDIO_SIGN_OUT_REDIRECT_URL as string;

export {
    REACT_APP_API_KEY,
    REACT_APP_BACKEND_BASE_URL,
    REACT_APP_BACKEND_PROMPT_INDEX_URL,
    REACT_APP_BACKEND_PROTECTED_URL,
    REACT_APP_BACKEND_PUBLIC_URL,
    REACT_APP_BACKEND_SENTENCE_INDEX_URL,
    REACT_APP_BACKEND_SOCKET_URL,
    REACT_APP_BACKEND_SOLUTION_INDEX_URL,
    REACT_APP_CLERK_PUBLISHABLE_KEY,
    REACT_APP_CLERK_SIGN_OUT_REDIRECT_URL,
    REACT_APP_STUDIO_SIGN_OUT_REDIRECT_URL,
};
