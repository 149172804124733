// A react component to display a list of properties, and allow the user to add new key value pair or edit existing ones.

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, TextField } from "@mui/material";
import React, { useState } from "react";

interface PropertiesEditBoxProps {
  properties: { [key: string]: string };
  propertiesChange: (properties: { [key: string]: string }) => void;
}

const PropertiesEditBox: React.FC<PropertiesEditBoxProps> = ({
  properties,
  propertiesChange,
}) => {
  const [newProperty, setNewProperty] = useState<{
    key: string;
    value: string;
  }>({ key: "", value: "" });

  const handleAddNewProperty = () => {
    const newProperties = { ...properties };
    newProperties[newProperty.key] = newProperty.value;
    propertiesChange(newProperties);
    setNewProperty({ key: "", value: "" });
  };

  const handlePropertyChange = (key: string, value: string) => {
    const newProperties = { ...properties };
    newProperties[key] = value;
    propertiesChange(newProperties);
  };

  const handleDeleteProperty = (key: string) => {
    const newProperties = { ...properties };
    delete newProperties[key];
    propertiesChange(newProperties);
  };

  return (
    <Box display="flex" flexDirection="column">
      {Object.entries(properties).map(([key, value]) => (
        <Box
          key={key}
          display="flex"
          justifyContent="space-between"
          alignItems="center">
          <TextField
            label="Key"
            value={key}
            onChange={(e) => handlePropertyChange(key, e.target.value)}
          />
          <TextField
            label="Value"
            value={value}
            onChange={(e) => handlePropertyChange(key, e.target.value)}
          />
          <Button onClick={() => handleDeleteProperty(key)}>
            <DeleteIcon />
          </Button>
        </Box>
      ))}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <TextField
          label="Key"
          value={newProperty.key}
          onChange={(e) =>
            setNewProperty({ ...newProperty, key: e.target.value })
          }
        />
        <TextField
          label="Value"
          value={newProperty.value}
          onChange={(e) =>
            setNewProperty({ ...newProperty, value: e.target.value })
          }
        />
        <Button onClick={handleAddNewProperty}>
          <AddIcon />
        </Button>
      </Box>
    </Box>
  );
};
export default PropertiesEditBox;
