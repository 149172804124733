import CancelIcon from "@mui/icons-material/Cancel";
import IconButton from "@mui/material/IconButton";
import React, { useEffect, useState, type FocusEvent } from "react";
import ContentEditable, { ContentEditableEvent } from "react-contenteditable";
import sanitizeHtml from "sanitize-html";
import { type SectionData } from "../../../types/SectionData";
import { type SectionElement } from "../../../types/SectionElement";
import styles from "./PromptInputBox.module.css";

interface PromptInputBoxProps {
    internalPrompt: string;
    setInternalPrompt: (newPrompt: string, allowHtml: boolean) => void;
    sectionData?: SectionData[];
    onSectionDataChange?: (newData: SectionData[]) => void;
    onPromptChange?: (newData: string) => void;
}

const defaultSectionData: SectionData[] = [
    {
        sectionName: "",
        sectionElement: [],
    },
    {
        sectionName: "",
        sectionElement: [],
    },
    {
        sectionName: "",
        sectionElement: [],
    },
    {
        sectionName: "",
        sectionElement: [],
    },
];

const PromptInputBox: React.FC<PromptInputBoxProps> = ({
    internalPrompt,
    setInternalPrompt,
    sectionData = defaultSectionData,
    onSectionDataChange,
    onPromptChange,
}) => {
    const [internalSectionData, setInternalSectionData] = useState(sectionData);
    const [isEditing, setIsEditing] = useState<string | null>(null);

    const sectionColors: Record<string, string> = {
        examples: "blue",
        instructions: "pink",
        metrics: "green",
        constraints: "red",
    };

    // Sync internal state with prop changes
    useEffect(() => {
        setInternalSectionData(sectionData);
    }, [sectionData]);

    //   handele chips edit
    const handleSectionDataChange = (newData: SectionData[]): void => {
        // update internal state
        setInternalSectionData(newData);
        // notify parent component
        onSectionDataChange?.(newData);
    };

    const handleChipEdit = (section: string, sentence: string): void => {
        setIsEditing(`${section}-${sentence}`);
    };

    const findSection = (
        internalSectionData: SectionData[],
        section: string
    ): SectionData | undefined => {
        return internalSectionData.find(
            (item: SectionData) => item.sectionName === section
        );
    };

    const findElementIndex = (
        section: SectionData,
        sentence: string
    ): number => {
        return section.sectionElement.findIndex(
            (el: SectionElement) => el.sentence === sentence
        );
    };

    const updateOrRemoveElement = (
        section: SectionData,
        index: number,
        newValue: string
    ): void => {
        if (newValue === "") {
            section.sectionElement.splice(index, 1);
        } else {
            section.sectionElement[index].sentence = newValue;
        }
    };

    const handleBlur = (
        section: string,
        sentence: string,
        e: FocusEvent<HTMLInputElement>
    ): void => {
        const targetSection = findSection(internalSectionData, section);
        if (targetSection != null) {
            const targetElementIndex = findElementIndex(
                targetSection,
                sentence
            );
            const newValue = e.target.value.trim();
            if (targetElementIndex !== -1) {
                updateOrRemoveElement(
                    targetSection,
                    targetElementIndex,
                    newValue
                );
                handleSectionDataChange([...internalSectionData]);
            }
        }
        setIsEditing(null);
    };

    const handleDelete = (section: string, id: string): void => {
        const targetSection = internalSectionData.find(
            (item) => item.sectionName === section
        );
        if (targetSection != null) {
            const targetElementIndex = targetSection.sectionElement.findIndex(
                (el) => el.sentence_id === id
            );
            if (targetElementIndex !== -1) {
                // Remove the element
                targetSection.sectionElement.splice(targetElementIndex, 1);
                // Update the state and notify the parent component
                handleSectionDataChange([...internalSectionData]);
            }
        }
    };

    // const handleKeyDown = (
    //   section: string,
    //   sentence: string,
    //   e: React.KeyboardEvent<HTMLInputElement>
    // ): void => {
    //   if (e.key === "Enter") {
    //     handleBlur(
    //       section,
    //       sentence,
    //       e as unknown as React.FocusEvent<HTMLInputElement>
    //     );
    //   }
    // };

    const onContentChange = (evt: ContentEditableEvent) => {
        if (
            evt.target?.value === undefined ||
            evt.target?.value === null ||
            evt.target?.value === ""
        ) {
            setInternalPrompt("", true);
            onPromptChange?.("");
            return;
        }

        const sanitized = sanitizeHtml(evt.target.value, {
            allowedTags: ["div"],
            allowedAttributes: {},
        });
        const val =
            sanitized === undefined || sanitized === null || sanitized === ""
                ? ""
                : sanitized;

        setInternalPrompt(val, true);
        // notify parent component
        onPromptChange?.(val);
    };

    return (
        <div className={styles.customTextField}>
            <ContentEditable
                className={styles.editableContent}
                suppressContentEditableWarning={true}
                contentEditable={true}
                data-placeholder="Enter text here..."
                onChange={onContentChange}
                html={internalPrompt}
            />
            {internalSectionData.map((item) => (
                <React.Fragment key={item.sectionName}>
                    <div className="sectionWrapper">
                        {item.sectionElement.length > 0 ? (
                            <div className={styles.sectionLabel}>
                                {item.sectionName}:
                            </div>
                        ) : (
                            <div className={styles.sectionLabel}> </div>
                        )}
                        {item.sectionElement.map((el) => (
                            <div
                                key={el.sentence_id}
                                className={styles.chip}
                                style={{
                                    backgroundColor:
                                        sectionColors[item.sectionName],
                                }}
                                onClick={() => {
                                    handleChipEdit(
                                        item.sectionName,
                                        el.sentence
                                    );
                                }}
                            >
                                {isEditing ===
                                `${item.sectionName}-${el.sentence}` ? (
                                    <input
                                        className={styles.input}
                                        defaultValue={el.sentence}
                                        size={el.sentence.length}
                                        onBlur={(e) => {
                                            handleBlur(
                                                item.sectionName,
                                                el.sentence,
                                                e
                                            );
                                        }}
                                        // onKeyDown={(e) => {
                                        //   handleKeyDown(item.sectionName, el.sentence, e);
                                        // }}
                                    />
                                ) : (
                                    <>
                                        <span className={styles.element}>
                                            {el.sentence}
                                        </span>
                                        <IconButton
                                            className={styles.deleteBtn}
                                            onClick={(e) => {
                                                e.stopPropagation(); // Prevent click from propagating to parent div
                                                handleDelete(
                                                    item.sectionName,
                                                    el.sentence_id
                                                );
                                            }}
                                            size="small"
                                        >
                                            <CancelIcon
                                                fontSize="inherit"
                                                style={{ color: "#FFF" }}
                                            />
                                        </IconButton>{" "}
                                    </>
                                )}
                            </div>
                        ))}
                    </div>
                </React.Fragment>
            ))}
        </div>
    );
};

export default PromptInputBox;
