import { Box } from "@mui/material";
import React from "react";
import sanitizeHtml from "sanitize-html";
import { IChatParticipant } from "../../../models/interfaces/workbench/chat/IChatParticipant";
import { IMessage } from "../../../models/interfaces/workbench/chat/IMessage";
import { chatMessageBodyStyle, chatMessageStyle } from "./ChatMessagesStyle";

interface IChatMessageProp {
    chatParticipantProfiles: Map<string, IChatParticipant>;
    message: IMessage;
    index: number;
}

export const ChatMessage: React.FC<IChatMessageProp> = ({
    chatParticipantProfiles,
    message,
    index,
}: IChatMessageProp) => {
    if (message?.llmMessage?.role === "system") {
        return (
            <Box key={index} sx={chatMessageStyle}>
                <Box component="span" sx={{ fontWeight: "bold" }}>
                    System:
                </Box>
                <Box sx={chatMessageBodyStyle}>
                    {sanitizeHtml(message?.llmMessage?.content, {
                        allowedTags: ["br", "a"],
                        allowedAttributes: { a: ["href"] },
                    })}
                </Box>
            </Box>
        );
    } else {
        return (
            <Box key={index} sx={chatMessageStyle}>
                <Box component="span" sx={{ fontWeight: "bold" }}>
                    {`${
                        chatParticipantProfiles.get(message.fromUserId)
                            ?.participantName
                    }`}
                </Box>
                {" to"}
                <Box component="span" sx={{ fontWeight: "bold" }}>
                    {chatParticipantProfiles.has(message.toUserIds[0])
                        ? ` ${
                              chatParticipantProfiles.get(message.toUserIds[0])
                                  ?.participantName
                          }`
                        : ""}
                </Box>
                {": "}
                <Box sx={chatMessageBodyStyle}>
                    {sanitizeHtml(message?.llmMessage?.content, {
                        allowedTags: ["br", "a"],
                        allowedAttributes: { a: ["href"] },
                    })}
                </Box>
            </Box>
        );
    }
};
